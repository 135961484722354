import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { IoMdHeart } from "react-icons/io";
import { Spinner as SpinnerBoot } from "react-bootstrap";
import { SendPostRequest } from "../../../../http";
import Spinner from "../../../Spinner";
function TipsTricks({ setTotkeyId }) {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [totkeyFavItem, setTotkeyFavItem] = useState([]);
  const [loadingItem, setLoadingItem] = useState(false);
  const [loadingFavItem, setLoadingFavItem] = useState(null);

  const getTotkeyFavItem = async () => {
    setLoadingItem(true);
    const response = await SendPostRequest("healthcare/fav-totkey-items", {
      logged_id: patientData?.logged_id,
      // logged_id: "663",
    });
    setTotkeyFavItem(response?.data);
    setLoadingItem(false);
  };

  const removeFav = async (id) => {
    setLoadingFavItem(id);
    const response = await SendPostRequest("home/totkey_fav", {
      totkey_id: id,
      logged_id: patientData?.logged_id,
      // logged_id: "663",
      fav_action: false,
    });
    setLoadingFavItem(null);
    if (response?.status === "success") {
      // alert(response?.message);
      getTotkeyFavItem();
    }
  };

  useEffect(() => {
    getTotkeyFavItem();
  }, []);

  return (
    <div className="tips">
      {loadingItem ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : totkeyFavItem?.length === 0 ? (
        <div className="text-center">No Favourite Totkey item found!</div>
      ) : (
        totkeyFavItem?.map((data, index) => (
          <div className="tip" key={index}>
            {loadingFavItem && loadingFavItem === data?.id ? (
              <SpinnerBoot />
            ) : (
              <IoMdHeart
                onClick={() => removeFav(data?.id)}
                className="cur-pointer fs-4 heart_icon"
              />
            )}
            {/* <RxCross2 onClick={() => removeFav(data?.id)} className="RxCross" /> */}
            <p className="cur-pointer " onClick={() => setTotkeyId(data?.id)}>
              {data?.heading}
            </p>
          </div>
        ))
      )}
    </div>
  );
}

export default TipsTricks;
