import React from "react";

function Captcha({
  captcha,
  inputCaptcha,
  textColorClass,
  checkCaptcha,
  handleInputChange,
}) {
  return (
    <>
      <div className="cs_with_icon_input">
        <input
          type="text"
          className="appointmentform_input mb-0"
          id="captchaInput"
          placeholder="Enter the captcha"
          value={inputCaptcha}
          onChange={handleInputChange}
          
          />
          <div className={`captcha-render-theme-2 `}>
          {captcha}
        </div>
        <span className={`${textColorClass} `}>{checkCaptcha}</span>
      </div>
      <div className="cs_height_42 cs_height_xl_25"></div>
          </>
    
  );
}

export default Captcha;
