import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { capitalize } from "@mui/material";
import { SendPostRequest } from "../../http";
import { toast } from "react-toastify";
import Header from "../Header";
import ThirdFooter from "../Footer/ThirdFooter";

function DropCv() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [cvData, setCvData] = useState({
    full_name: "",
    email: "",
    contact_no: "",
    // position: "",
    address: "",
    segments: "",
    experience: "",
    education: "",
    certifications: "",
    previous_work: "",
    languages: "",
    linkedin: "",
    message: "",
    cv_file: null,
    cv_ext: "",
  });

  const [backgroundImage, setBackgroundImage] = useState("");
  const [positionApplyFor, setPositionApplyFor] = useState([]);
  const [careersData, setCareersData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [segmentList, setSegmentList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getCareerData();
    getSegmentData();
  }, [sitedata]);

  const getSegmentData = async () => {
    const response = await SendPostRequest("segments/list");
    setSegmentData(response?.data);
    const options = response?.data.map((item) => ({
      value: item.uid,
      label: item.title,
    }));
    setSegmentList(options);
  };

  const getCareerData = async () => {
    const response = await SendPostRequest("careers/jobs");
    setCareersData(response?.data);
    const options = response?.data?.jobs?.map((item) => ({
      value: item.uid,
      label: item.title,
    }));
    setPositionApplyFor(options);
  };

  const handleFileChange = (e) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setCvData((prevData) => ({
        ...prevData,
        cv_ext: file.name.split(".").pop(),
        [name]: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cvData.full_name) {
      toast.error("InComplete Form");
      return;
    } else if (!cvData.email) {
      toast.error("InComplete Form");
      return;
    } else if (!cvData.contact_no) {
      toast.error("InComplete Form");
      return;
    } else if (
      cvData?.contact_no.length > 14 ||
      cvData?.contact_no.length < 10
    ) {
      toast.error("Invalid Contact Number");
      return;
    } else if (cvData?.segments === "") {
      toast.error("InComplete Form");
      return;
    } else if (!cvData?.cv_file) {
      toast.error("InComplete Form");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await SendPostRequest("careers/drop-cv", cvData);
      formRef.current.reset();
      if (response.status === "success") {
        setCvData({
          full_name: "",
          email: "",
          contact_no: "",
          // position: "",
          address: "",
          segments: "",
          experience: "",
          education: "",
          certifications: "",
          previous_work: "",
          languages: "",
          linkedin: "",
          message: "",
          cv_file: null,
          cv_ext: "",
        });
        toast.success(response.message);
      } else {
        let errorMessage = response.message || "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        console.error("Error fetching data:", errorMessage);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error("Error fetching data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCvData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Header />
      <div class="page-title-wrap typo-white">
        <div
          class="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span class="black-overlay"></span>
          <div class="container">
            <div class="row text-left">
              <div class="col-md-12">
                <div class="page-title-inner">
                  <h1 class="page-title mb-0">Drop Your Cv</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="contact-form" data-animation="fadeInLeft">
          <form ref={formRef} onSubmit={handleSubmit}>
            <div className="row p-4">
              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Full Name <span className="asterisk">*</span>
                </label>
                <input
                  type="text"
                  className="input-name form-control"
                  placeholder="Enter Full Name"
                  name="full_name"
                  value={cvData?.full_name}
                  onChange={handleInputChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  className="input-name form-control"
                  placeholder="Enter Email Address"
                  name="email"
                  value={cvData?.email}
                  onChange={handleInputChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Contact Number <span className="asterisk">*</span>
                </label>
                <input
                  type="number"
                  className="input-name form-control"
                  placeholder="Enter Contact Number"
                  name="contact_no"
                  value={cvData?.contact_no}
                  onChange={handleInputChange}
                  maxLength={11}
                  minLength={10}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Departments <span className="asterisk">*</span>
                </label>
                <Select
                  className="drop_cv_select"
                  name="segments"
                  options={segmentList}
                  placeholder="Select...."
                  value={segmentList.find(
                    (option) => option?.value === cvData?.segments
                  )}
                  onChange={(option) =>
                    setCvData((prevData) => ({
                      ...prevData,
                      segments: option?.value,
                    }))
                  }
                />

                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Upload Your CV <span className="asterisk">*</span>
                </label>
                <input
                  type="file"
                  className="input-name form-control drop_cv_pic_upoload_field"
                  placeholder="Upload CV"
                  name="cv_file"
                  onChange={handleFileChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">Message</label>
                <input
                  className="input-name form-control "
                  placeholder="Type Your Message Here"
                  name="message"
                  value={cvData?.message}
                  onChange={handleInputChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              <div className="col-lg-12 mb-3">
                <div className="cs_height_18"></div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="btn btn-default"
                    style={{ width: "100px" }}
                  >
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ThirdFooter />
    </>
  );
}

export default DropCv;
