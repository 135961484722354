import React, { useEffect, useState } from "react";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BloodGlucoseChart from "./BloodGlucoseChart";
import { toast } from "react-toastify";
import WeightManageChart from "./WeightManageChart";
import { formatDate, getPreviousDate, today } from "../../../Helper/Helper";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../../../http";

function BloodGlucose() {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [showModal, setShowModal] = useState(false);
  const [getData, setGetData] = useState({});
  const [loadingGraph, setLoadingGraph] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previousDate, setPreviousDate] = useState(getPreviousDate(7));
  const [addData, setAddData] = useState({
    measurement: null,
    measurement_type: "",
    record_date: "",
    record_time: "",
    notes: "",
  });
  const today = new Date();

  const timeStamp = [
    { value: "1-week", label: "1 Week" },
    { value: "2-week", label: "2 Weeks" },
    { value: "3-week", label: "3 Weeks" },
    { value: "4-week", label: "4 Weeks" },
  ];
  const measurementType = [
    { value: "After Meal", label: "After Meal" },
    { value: "Fasting", label: "Fasting" },
    { value: "Random", label: "Random" },
  ];
  const [selectedTimeStamp, setSelectedTimeStamp] = useState(timeStamp[0]);

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchData();
  }, [selectedTimeStamp]);

  const fetchData = async (value) => {
    setLoadingGraph(true);
    try {
      const response = await SendPostRequest("healthcare/blood_glucose/graph", {
        // logged_id: "858",
        logged_id: patientData?.logged_id,
        timespan: selectedTimeStamp?.value || "",
      });
      if (response?.status === "success") {
        // toast.success(response?.message);
        setGetData(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoadingGraph(false);
      setPreviousDate(
        getPreviousDate(7 * +selectedTimeStamp?.value?.slice(0, 1))
      );
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    setAddData((prv) => ({ ...prv, [name]: value }));
  };
  const handleSelectChange = (e) => {
    setAddData((prv) => ({ ...prv, measurement_type: e.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      // logged_id: "858",
      logged_id: patientData?.logged_id,
      form_data: addData,
    };
    setLoading(true);
    try {
      const response = await SendPostRequest(
        "healthcare/blood_glucose/add",
        data
      );
      if (response?.status === "success") {
        toast.success(response?.message);
        handleModalClose();
        fetchData();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="weight_management_header">
        <h4>Blood Glucose</h4>
        <p>
          ({formatDate(previousDate)}) to ({formatDate(today)})
        </p>
        <div className="weight_management_right_content">
          <button
            className="weight_management_modal_btn"
            onClick={handleModalOpen}
          >
            +
          </button>

          <Select
            name="title"
            className="weight_management_dropdown"
            defaultValue={selectedTimeStamp}
            options={timeStamp}
            onChange={(e) => {
              setSelectedTimeStamp(e);
            }}
          />
          <Select
            name="title"
            className="blood_measurement_type_dropdown"
            placeholder="Measurement Type"
            options={measurementType}
          />
        </div>
      </div>

      <div className="pt-5">
        <WeightManageChart data={getData} loading={loadingGraph} />
        {/* <BloodGlucoseChart /> */}
      </div>

      {/* <div className=" graph_image pt-5">
        <img src="/assets/img/graph_img.jpeg" alt="Graph" />
      </div> */}

      <Modal
        className="modal-wrapper"
        show={showModal}
        onHide={handleModalClose}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="weight_management_modal-title">
              Blood Glucose
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Measurement
                </label>
                <input
                  required
                  type="number"
                  className="weight_management_modal_input"
                  placeholder="Enter Weight In Kg"
                  name="measurement"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">
                  Measurement Type
                </label>
                <Select
                  className="blood_measurement_type_modal_select"
                  options={measurementType}
                  placeholder="Measurement Type"
                  name="measurement_type"
                  onChange={handleSelectChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">Time</label>
                <input
                  required
                  type="time"
                  className="weight_management_modal_input"
                  placeholder="Select Date"
                  name="record_time"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6">
                <label className="cs_input_label cs_heading_color">Date</label>
                <input
                  required
                  type="date"
                  className="weight_management_modal_input"
                  placeholder="Select Date"
                  name="record_date"
                  onChange={handleOnChange}
                  max={today}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              <div className="col-lg-12">
                <label className="cs_input_label cs_heading_color">Note</label>
                <input
                  required
                  type="text"
                  className="weight_management_modal_input"
                  placeholder="Enter Your Note"
                  name="notes"
                  onChange={handleOnChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="weight_management_submit-button"
              variant="secondary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Submiting..." : "Submit"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default BloodGlucose;
