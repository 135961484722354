import React, { useEffect } from "react";
import * as echarts from "echarts";

function BloodGlucoseChart() {
  useEffect(() => {
    // Initialize ECharts instance
    const chartDom = document.getElementById("main");
    const myChart = echarts.init(chartDom);

    // Define chart options
    const option = {
      title: {
        text: "Stacked Line",
      },
      tooltip: {
        trigger: "axis",
      },
      legend: {
        data: ["Email", "Union Ads", "Video Ads", "Direct", "Search Engine"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "category",
        boundaryGap: false,
        data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Email",
          type: "line",
          stack: "Total",
          data: [120, 132, 101, 134, 90, 230, 210],
        },
        {
          name: "Union Ads",
          type: "line",
          stack: "Total",
          data: [220, 182, 191, 234, 290, 330, 310],
        },
        {
          name: "Video Ads",
          type: "line",
          stack: "Total",
          data: [150, 232, 201, 154, 190, 330, 410],
        },
        {
          name: "Direct",
          type: "line",
          stack: "Total",
          data: [320, 332, 301, 334, 390, 330, 320],
        },
        {
          name: "Search Engine",
          type: "line",
          stack: "Total",
          data: [820, 932, 901, 934, 1290, 1330, 1320],
        },
      ],
    };

    // Set chart options
    option && myChart.setOption(option);

    // Clean up function to destroy the chart instance when component unmounts
    return () => {
      myChart.dispose();
    };
  }, []); // Empty dependency array ensures useEffect runs only once after initial render

  return <div id="main" style={{ width: "100%", height: "500px" }}></div>;
}

export default BloodGlucoseChart;
