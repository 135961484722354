import axios from "axios";

const server = "https://builder-api.clinta.biz/";

const headers = {
  "Content-Type": "application/json",
  "clinta-api-key": "93cc1a25ba732d87a01fe284dd60ac38",
  Cookie: "ci_session=1119dadab047c99c5a94c726bd9278436f43feec",
  "Access-Control-Allow-Origin": "*",
};

export async function SendPostRequest(endpoint, data = {}) {
  let login_type = localStorage.getItem("login_type");

  const currenthost = window.location.hostname;

  let defaulthost = "";
  if (currenthost === "localhost") {
    // defaulthost = "reliancehospital.org";
    defaulthost = "h-theme3.clinta.biz";
    // defaulthost = "doct-theme3.clinta.biz";
    // defaulthost = "drburhan.clinta.biz";
    // defaulthost = "relianceaesthetics.com";
    // defaulthost = "usmanhospital.clinta.biz";
    // defaulthost = "usmandoctor.clinta.biz";
    // defaulthost = "javeddoctor.clinta.biz";
    // defaulthost = "reliancehomehealth.org";
    // defaulthost = "haadiyahhospital.clinta.biz";
  } else {
    if (currenthost === "doct-theme3.clinta.biz") {
      defaulthost = "drburhan.clinta.biz";
    } else {
      defaulthost = currenthost;
    }
  }

  data.host = defaulthost;
  const response = await axios
    .post(server + endpoint, data, {
      headers: headers,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error", error);
      return error;
    });
  console.log("This is api response " + endpoint, response.data);
  return response.data;
}

export async function SendGetRequest(endpoint, data) {
  return await axios
    .get(server + endpoint, {
      params: data,
    })
    .then(function (response) {
      console.log(response);
      return response;
    })
    .catch(function (error) {
      console.log("error", error);
      return error;
    });
}

export async function SendPutRequest(endpoint, data) {
  return await axios
    .put(server + endpoint, data, {
      headers: headers,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error", error);
      return error;
    });
}

export async function SendDeleteRequest(endpoint) {
  return await axios
    .delete(server + endpoint)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error", error);
      return error;
    });
}
