import React, { useEffect } from "react";

import Footer from "../Footer";
import Header from "../Header";
import {Link} from "react-router-dom";
export default function EHealthAppointment() {
    useEffect(() => {
        document.title = "E health Appointment";
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <section
                className="cs_banner cs_style_5 cs_bg_filed"
                style={{ backgroundImage: "url('/assets/img/doctors/banner_bg.svg')" }}
            >
                {" "}
                {/* <div className="cs_banner_img">
                    <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
                </div> */}
                <div className="container">
                    <div className="cs_banner_text">
                        <h2 className="cs_banner_title cs_fs_72">
                            WELCOME "SHAHERYAR MALIK"</h2>
        
                    </div>
                </div>
            </section>

            <section>
                <div className="cs_height_125 cs_height_xl_125 cs_height_lg_85"></div>
                <div className={"container"}>
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-8">
                                <h5 className="cs_banner_title cs_fs_72">
                                    All E Health Appointment
                                </h5>
                                <div className="cs_list cs_style_3">
                                    <table>
                                        <thead>
                                        <tr className="cs_list_title cs_medium cs_fs_24">
                                            <th>Doctor Name</th>
                                            <th>Appointment</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody className="cs_mp0 cs_heading_color cs_radius_20 cs_white_bg cs_medium">
                                        <tr>
                                            <td>Shreyar</td>
                                            <td>fgdf</td>
                                            <td>gseg</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <h4>Panel</h4>
                                <div className="col-lg-12">
                                    <div className="cs_height_15"></div>
                                    <button className="cs_btn cs_style_1 common-btn">
                                        <Link to={"/patient"}><span>Dashboard</span></Link>
                                    </button>
                                </div>

                                <div className="col-lg-12">
                                    <div className="cs_height_15"></div>
                                    <button className="cs_btn cs_style_1 common-btn">
                                        <Link to={"/patient/appointments"}><span>Basic Appointments</span></Link>
                                    </button>
                                </div>

                                <div className="col-lg-12">
                                    <div className="cs_height_15"></div>
                                    <button className="cs_btn cs_style_1 common-btn">
                                        <Link to={"/patient/e_health_appointment"}><span>e-health Appointments</span></Link>
                                    </button>
                                </div>

                                <div className="col-lg-12">
                                    <div className="cs_height_15"></div>
                                    <button className="cs_btn cs_style_1 common-btn">
                                        <Link to={"/patient/appointments"}><span>Logout</span></Link>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></section>
                    <Footer />
        </>
    );
}
