import React, { useEffect } from "react";
// import "./../Theme2Components/theme2.css";
import Header from "./Header";
import Footer from "./Footer/Footer";
import HomeServices from "./HomeServices";
import LatestWorks from "./LatestWorks";
import HomeFeatures from "./HomeFeatures";
import HomeAchievement from "./HomeAchievement";
import OurClients from "./OurClients";
import SecondHeader from "./SecondHeader";
import FirstIntroduction from "./IntroductionSections/FirstIntroduction";
import SecondIntroduction from "./IntroductionSections/SecondIntroduction";
import ThirdIntroduction from "./IntroductionSections/ThirdIntroduction";
import FifthIntroduction from "./IntroductionSections/FifthIntroduction";
import FourthIntroduction from "./IntroductionSections/FourthIntroduction";
import FirstFeaturedServices from "./Services/FirstFeaturedServices";
import SecondFeaturedServices from "./Services/SecondFeaturedServices";
import ThirdFeaturedServices from "./Services/ThirdFeaturedServices";
import SecondFooter from "./Footer/SecondFooter";
import FirstSlider from "./HeaderSliders/FirstSlider";
import FirstFacilities from "./HomeFacilities/FirstFacilities";
import { useSelector } from "react-redux";
import SecondFacilities from "./HomeFacilities/SecondFacilities";
import ThirdFooter from "./Footer/ThirdFooter";
import CeoMessage from "./CeoMessage";
import HomeNews from "./NewsUpdates/HomeNews";
import HomeReviews from "./Reviews/HomeReviews";
import HomeIntro from "./HomeIntro";
import ThirdMainSlider from "./HeaderSliders/ThirdMainSlider";
import SecondSlider from "./HeaderSliders/SecondSlider";

export default function Home({ isData, setIsdata }) {
  const sitedata = useSelector((store) => store.global.loginData);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {sitedata?.theme_header === "version_1" ? <Header /> : <SecondHeader />}

      {sitedata?.options?.banner_style === "version3" ? (
        <SecondSlider />
      ) : sitedata?.options?.banner_style === "version4" ? (
        <ThirdMainSlider />
      ) : (
        <FirstSlider />
      )}

      <HomeIntro />
      {/* <SecondSlider setIsdata={setIsdata} /> */}
      {/* <FirstIntroduction /> */}
      {/* <SecondIntroduction /> */}
      {/* <ThirdIntroduction/> */}
      {/* <FourthIntroduction /> */}
      {/* <FifthIntroduction /> */}
      {/* <FirstFeaturedServices setIsdata={setIsdata} /> */}
      {/* <SecondFeaturedServices setIsdata={setIsdata} /> */}
      <ThirdFeaturedServices setIsdata={setIsdata} />
      {sitedata?.site_type === "hospitals" && <FirstFacilities />}
      <HomeNews />
      {/* {sitedata?.site_type === "hospitals" && <SecondFacilities />} */}
      {/* <section
        id="section-about"
        className="section-about pad-top-20 pad-bottom-45"
      >
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6 pe-lg-5" data-animation="fadeInLeft">
              <div className="about-wrap relative">
                <div className="about-wrap-inner">
                  <div className="about-wrap-details">
                    <div className="text-center">
                      <div className="about-img">
                        <img
                          src="/assets/images/about-us/about-5.jpg"
                          className="tr-radius"
                          alt="about-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="counter-wrapper counter-style-2 broken-style mb-4 mb-lg-0">
                  <div className="counter-value text-center">
                    <h2 className="mb-1">
                      <span className="counter-up" data-count="32">
                        0
                      </span>
                      <span className="counter-suffix">K</span>
                    </h2>
                  </div>
                  <div className="counter-details text-center">
                    <div className="counter-title">
                      <p className="mb-0">Projects Delivered</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 pad-tb-100" data-animation="fadeInRight">
              <div className="title-wrap margin-bottom-20">
                <div className="section-title">
                  <div className="section-title margin-bottom-40">
                    <h2 className="title text-uppercase mb-0">
                      Tell Your Dreams{" "}
                      <span className="theme-color">We Make It Real</span>
                    </h2>
                    <span className="section-border-bottom"></span>
                  </div>
                </div>
                <div className="pad-top-5">
                  <p className="margin-bottom-15">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable. If you are going to use a
                    passage of Lorem Ipsum.
                  </p>
                </div>
              </div>
              <div className="feature-box-wrap quote-style mb-3">
                <div className="feature-box-details">
                  <div className="media">
                    <div className="quote-icon align-self-center me-4">
                      <span className="ti-quote-right theme-color"></span>
                    </div>
                    <div className="media-body">
                      <div className="quote-content">
                        <p className="mb-0">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="margin-bottom-30">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis.
              </p>
              <div className="button">
                <a href="about-us.html" className="btn btn-default">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <CeoMessage />
      <HomeReviews />
      {/* <HomeAchievement /> */}
      {/* <HomeFeatures /> */}
      {/* <LatestWorks /> */}
      {/* <HomeServices /> */}
      {/* <OurClients /> */}
      {/* <Footer /> */}
      <SecondFooter />
      {/* <ThirdFooter /> */}
    </>
  );
}
