import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./FirstSlider.css";
import { SendPostRequest } from "../../http";

function FirstSlider() {
  const [homeBanners, setHomeBanners] = useState([]);

  const brandingData = async () => {
    const response = await SendPostRequest("branding/banners");

    setHomeBanners(response?.data);
  };

  useEffect(() => {
    brandingData();
  }, []);

  return (
    <div className="carousel-container fslider-wrapper">
      <Carousel fade controls={false} interval={5000} slide={true}>
        {homeBanners.map((banner, index) => (
          <Carousel.Item key={index}>
            <div className="carousel-item-container">
              <img
                className="d-block w-100 scaling-image"
                src={banner?.banner_image}
                alt={`Slide ${index + 1}`}
              />
              <div className="carousel-overlay" />
              <div className="carousel-content">
                <div>
                  <h1 className="type">{banner?.banner_title}</h1>
                </div>
                <p>{banner?.banner_description}</p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default FirstSlider;
