import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../../../../http";
import Spinner from "../../../Spinner";

function RecentItems({ setFruitId }) {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [recentDietItem, setRecentDietItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRecentDietItem = async () => {
    setLoading(true);
    const response = await SendPostRequest("home/recent-fruits", {
      logged_id: patientData?.logged_id,
      // logged_id: "663",
    });
    setRecentDietItem(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    getRecentDietItem();
  }, []);

  // const dietItems = [
  //   "/assets/img/image.jpeg",
  //   "/assets/img/image.jpeg",
  //   "/assets/img/image.jpeg",
  //   "/assets/img/image.jpeg",
  //   "/assets/img/image.jpeg",
  // ];
  return (
    <div className="row">
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : recentDietItem?.length === 0 ? (
        <div className="text-center">No Recent Totkey item found!</div>
      ) : (
        recentDietItem?.map((data, index) => (
          <div
            onClick={() => setFruitId(data?.UID)}
            className="col-lg-3 col-md-4 col-sm-6 "
            key={index}
          >
            <div className="cur-pointer recent-dietCard">
              <p className="mb-0">{data?.Name}</p>
              <p className="mb-0 text-end"> {data?.UrduName}</p>
              <img src={data?.Image} alt="" />
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default RecentItems;
