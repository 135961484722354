import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Header from "./Header";
import Footer from "./Footer/Footer";
import { capitalize } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { SendPostRequest } from "../http";
import { setPatientLogin } from "../store/global/actions";

function Login() {
  const sitedata = useSelector((store) => store.global.loginData);
  const dispatch = useDispatch();
  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Login - ClinTa";
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const profileCheck = useSelector((store) => store.global.profileCheck);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [sendMailLoading, setSendMailLoading] = useState(false);
  const [patientisLoading, setPatientisLoading] = useState(false);
  const [hospitalisLoading, setHospitalisLoading] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setHospitalisLoading(true);

    if (username.length === 0) {
      toast.error("Enter Email");
      setHospitalisLoading(false);
      return;
    } else if (!username.includes("@")) {
      toast.error("Invalid Email");
      setHospitalisLoading(false);
      return;
    }
    try {
      const response = await SendPostRequest("auth/login", {
        login_email: username,
        login_password: password,
        // login_type: sitedata?.site_type,
      });
      setUsername([]);
      setPassword([]);

      if (response.data) {
        if (response.status === "success") {
          const redirectUrl = response?.data.url;
          if (redirectUrl) {
            window.open(redirectUrl, "_blank");
          }

          toast.success("You are logged in");
        } else {
          setHospitalisLoading(false);
          toast.error("User Not Found");
        }
      } else {
        if (response.errorCode === "INVALID_CREDENTIALS") {
          toast.error("Incorrect username or password");
          setHospitalisLoading(false);
        } else {
          toast.error("Login failed. Please check your credentials.");
          setHospitalisLoading(false);
        }
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      console.error("Login failed:", error);
      setHospitalisLoading(false);
    } finally {
      setHospitalisLoading(false);
    }
  };

  const [contactNum, setContactNum] = useState("");
  const [patientPassword, setpatientPassword] = useState("");
  const handleUserContactChange = (e) => {
    setContactNum(e.target.value);
  };

  const handlePatientPasswordChange = (e) => {
    setpatientPassword(e.target.value);
  };

  const handlePatientLogin = async (e) => {
    e.preventDefault();
    setPatientisLoading(true);

    if (contactNum.length === 0) {
      toast.error("Enter Email / Phone Number");
      setPatientisLoading(false);
      return;
    } else if (patientPassword.length === 0) {
      toast.error("Enter your Password");
      setPatientisLoading(false);
      return;
    }

    try {
      const response = await SendPostRequest("auth/patient_login", {
        contactnumber: contactNum,
        password: patientPassword,
        // login_type: sitedata?.site_type,
      });
      setContactNum([]);
      setpatientPassword([]);

      if (response.data) {
        if (response.status === "success") {
          dispatch(setPatientLogin(response?.data));
          toast.success("Patient logged in");
          navigate("/patient");
        } else {
          toast.error("User not found");
          setPatientisLoading(false);
        }
      } else {
        if (response.errorCode === "INVALID_CREDENTIALS") {
          toast.error("Incorrect username or password");
          setPatientisLoading(false);
        } else {
          toast.error("Login failed. Please check your credentials.");
          setPatientisLoading(false);
        }
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      console.error("Login failed:", error);
      setPatientisLoading(false);
    }
  };

  const [forgotEmail, setForgotEmail] = useState("");

  const handleChangeForgotEmail = (e) => {
    setForgotEmail(e.target.value);
  };

  const handleClickForgotEmail = async (e) => {
    if (forgotEmail === "") {
      toast.error("Enter Email");
      setSendMailLoading(false);
      return;
    } else if (!forgotEmail.includes("@")) {
      toast.error("Enter Valid Email");
      setSendMailLoading(false);
      return;
    }
    setSendMailLoading(true);

    // e.preventDefault();

    try {
      const response = await SendPostRequest("auth/forget_password", {
        action: "send_email",
        email: forgotEmail,
      });
      if (response?.status === "success") {
        toast.success(response?.message);
        setForgotEmail("");
        setIsOpen(false);
      } else {
        toast.error(response?.message);
        setSendMailLoading(false);
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      setSendMailLoading(false);
      console.error("Login failed:", error);
    }
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <ToastContainer />
      <Header />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          data-bg="/assets/images/bg/page-title-bg.jpg"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <div id="breadcrumb" className="breadcrumb mb-1 mb-lg-2">
                    <a href="index.html" className="theme-color">
                      Home
                    </a>
                    <span className="current">Pages</span>
                  </div>
                  <h1 className="page-title mb-0">Login</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wrapper pad-none pt-2">
        <div className="content-inner">
          <section id="section-contact" className="pad-top-5 pt-5 pb-2">
            <div className="container">
              <div className="row">
                <div className="offset-md-2 col-md-8">
                  <div className="title-wrap text-center">
                    <div className="section-title">
                      <h2 className="title mb-0 text-uppercase">
                        Login Your Account
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-5">
                  <form
                    id="contact-form"
                    className=" bg-grey pad-40"
                    method="post"
                  >
                    {sitedata.site_type == "hospitals" ? (
                      <h3 className="title mb-3">Hospital Login</h3>
                    ) : (
                      <h3 className="title mb-3">Doctor Login</h3>
                    )}
                    <div id="success"></div>
                    <input
                      className="form-control"
                      type="email"
                      name="name"
                      placeholder="Type Your Email"
                      value={username}
                      onChange={handleUsernameChange}
                      required
                    />
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Enter Your Password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <div className="clearfix"></div>
                    <button
                      type="submit"
                      onClick={handleLogin}
                      disabled={hospitalisLoading}
                      className="btn btn-default"
                    >
                      {hospitalisLoading ? "Please Wait" : "Login"}
                    </button>
                    <span className="pull-right">
                      <Link>Forget Password</Link>
                    </span>
                  </form>
                </div>
                <div className="col-lg-6 col-md-12 col-12 mb-5">
                  <form
                    id="contact-form"
                    className=" bg-grey pad-40"
                    method="post"
                  >
                    <h3 className="title mb-3">Patient Now</h3>
                    <div id="success"></div>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Enter Your Email/Phone Number"
                      value={contactNum}
                      onChange={handleUserContactChange}
                    />
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Enter Your Password"
                      value={patientPassword}
                      onChange={handlePatientPasswordChange}
                    />
                    <div className="clearfix"></div>
                    <button
                      type="button"
                      onClick={handlePatientLogin}
                      disabled={patientisLoading}
                      className="btn btn-default"
                    >
                      {patientisLoading ? "Please Wait" : "Login "}
                    </button>
                    <span className="pull-right">
                      <Link onClick={openModal}>Forgot Password?</Link>
                    </span>
                  </form>

                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    className="forget-pass-modal"
                    contentLabel="Example Modal"
                  >
                    <div className="forget-password-modal">
                      <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                        Password Recovery
                      </h2>
                      <label className="cs_input_label cs_heading_color">
                        Enter your email
                      </label>
                      <input
                        type="email"
                        className="cs_form_field"
                        placeholder="Enter Your Email"
                        value={forgotEmail}
                        onChange={handleChangeForgotEmail}
                      />
                      <button
                        onClick={handleClickForgotEmail}
                        disabled={sendMailLoading}
                      >
                        {sendMailLoading ? "Please Wait" : "Send Mail"}
                      </button>
                      <button onClick={closeModal}>Close</button>
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
