import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Theme1Routes from "./Theme1Routes";
import Theme2Routes from "./Theme2Routes";
import { SendPostRequest } from "./http";
import { setLogindata1, setProfileCheck } from "./store/global/actions";

function App() {
  const [data, setData] = useState({});
  const [isData, setIsdata] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);

  // import("./components/" + sitedata?.theme_color).then(() => {});
  // import("./components/red.css").then(() => {});
  // import("./components/black.css").then(() => {});
  // import("./components/gold.css").then(() => {});

  const dispatch = useDispatch();
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  useEffect(() => {
    getData();
    loginCheckData();
  }, []);

  const getData = async () => {
    const res = await SendPostRequest("branding/info");
    setData(res?.data);
    if (res?.data) {
      localStorage.setItem("login_type", res?.data?.site_type);
      dispatch(setLogindata1(res.data));
    }
  };

  const [loginCheck, setLoginCheck] = useState({});
  const loginCheckData = async () => {
    const res = await SendPostRequest("home/profile_check");
    setLoginCheck(res?.data[0]);
    dispatch(setProfileCheck(res?.data[0]));
  };

  return (
    <BrowserRouter>
      {sitedata?.options?.theme === "mist" ? (
        <Theme2Routes
          isData={isData}
          setIsdata={setIsdata}
          patientLogin={patientLogin}
        />
      ) : (
        <Theme1Routes
          isData={isData}
          setIsdata={setIsdata}
          patientLogin={patientLogin}
        />
      )}
    </BrowserRouter>
  );
}

export default App;
