import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { Spinner as SpinnerBoot } from "react-bootstrap";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";

const TotkeyDetail = ({ totkeyId }) => {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [loading, setLoading] = useState(false);
  const [loadingTotkey, setLoadingTotkey] = useState(false);
  const [totkeyDetail, setTotkeyDetail] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleToggle = async (id, fav) => {
    setLoading(true);
    const response = await SendPostRequest("home/totkey_fav", {
      totkey_id: id,
      logged_id: patientData?.logged_id,
      fav_action: !fav,
    });
    setLoading(false);
    if (response?.status === "success") {
      // alert(response?.message);
      fetchTotkeyDetail();
    }
  };

  useEffect(() => {
    fetchTotkeyDetail();
  }, [totkeyId]);

  const fetchTotkeyDetail = async () => {
    try {
      const response = await SendPostRequest("healthcare/totkey-item", {
        item_id: totkeyId,
        logged_id: patientData?.logged_id,
      });
      setTotkeyDetail(response?.data);

      if (response.status === "success") {
      } else {
        console.error("Error fetching totkey:", response.message);
      }
    } catch (error) {
      console.error("Error fetching totkey:", error);
    }
  };

  return loadingTotkey ? (
    <div className="text-center">
      <Spinner />
    </div>
  ) : (
    <div className="totkey-detailCard">
      <div className="d-flex justify-content-between align-items-center">
        <div
          onClick={() => handleToggle(totkeyDetail?.id, totkeyDetail?.Fav)}
          className="fs-1 cur-pointer"
        >
          {loading ? (
            <SpinnerBoot />
          ) : totkeyDetail?.Fav ? (
            <IoMdHeart className="heart_icon" />
          ) : (
            <IoMdHeartEmpty className="heart_icon" />
          )}
        </div>
        <h2 className="text-right">{totkeyDetail?.heading}</h2>
      </div>
      <p className="text-right">{totkeyDetail?.description}</p>
    </div>
  );
};

export default TotkeyDetail;
