import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

function ThirdFeaturedServices({ setIsdata }) {
  const [featuredService, setFeaturedService] = useState([]);
  const [loading, setLoading] = useState(true);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    document.title = "Home";
    getFeaturedService();
  }, []);

  const getFeaturedService = async () => {
    setLoading(true);
    const response = await SendPostRequest("services/featured_services");
    if (response?.data.length < 5 && sitedata?.site_type === "hospitals") {
      setIsdata(true);
    }
    setFeaturedService(response?.data);
    setLoading(false);
  };

  const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    animateIn: "slideInRight",
    animateOut: "slideOutLeft",
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        featuredService?.length > 0 && (
          <section
            id="services-section"
            className="services-section semi-dark py-5"
          >
            <div className="container">
              <div className="row">
                <div className="offset-md-2 col-md-8">
                  <div className="title-wrap text-center">
                    <div className="section-title margin-bottom-60">
                      <h2 className="section-title mb-0 text-uppercase">
                        Our <span className="theme-color">Specialities</span>
                      </h2>
                      <span className="section-border-bottom center"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <OwlCarousel className="service-main-wrapper" {...options}>
                    {featuredService?.map((service, index) => (
                      <div className="item" key={index}>
                        <div className="service-box-wrap service-box-style-2 mb-5">
                          <div className="service-box-inner">
                            <div className="service-thumb">
                              <img
                                className="img-fluid squared w-100 third-fservices-image"
                                src={service?.service_image_url}
                                alt={service?.title}
                              />
                            </div>
                            <div className="service-main-content">
                              <h3 className="mb-0 service-title">
                                {service?.service_title}
                              </h3>
                              <div className="service-link">
                                <Link
                                  to={`/speciality/${
                                    service?.uid
                                  }/${service?.service_title
                                    .replaceAll(" ", "-")
                                    .replaceAll("/", "-")}`}
                                  rel="noopener noreferrer"
                                  className="link"
                                >
                                  <i className="ti-arrow-top-right"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="service-overlay-content">
                              <div className="service-meta">
                                <h3 className="mb-2 service-title">
                                  <a href={service?.link} className="">
                                    {service?.service_title}
                                  </a>
                                </h3>
                                <div className="service-link">
                                  <Link
                                    to={`/speciality/${
                                      service?.uid
                                    }/${service?.service_title
                                      .replaceAll(" ", "-")
                                      .replaceAll("/", "-")}`}
                                    rel="noopener noreferrer"
                                    className="link"
                                  >
                                    <i className="ti-arrow-top-right"></i>
                                  </Link>
                                </div>
                              </div>
                              <div
                                className="service-desc mb-4"
                                dangerouslySetInnerHTML={{
                                  __html: service.service_description.slice(
                                    0,
                                    80
                                  ),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
}

export default ThirdFeaturedServices;
