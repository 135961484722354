import React, { useEffect, useState } from "react";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import "./SecondSlider.css";
import { SendPostRequest } from "../../http";

function SecondSlider({ isData, setIsdata }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const [homeBanners, setHomeBanners] = useState([]);

  useEffect(() => {
    brandingData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [activeIndex]);

  const brandingData = async () => {
    const response = await SendPostRequest("branding/banners");

    setHomeBanners(response?.data);
  };

  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === homeBanners?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? homeBanners?.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className="slider-container">
      <div className="slideshow">
        {homeBanners?.map((slide, index) => (
          <div key={index}>
            <div
              className={`slide left ${
                index === activeIndex ||
                index === activeIndex - 1 ||
                (index === homeBanners?.length - 1 && activeIndex === 0)
                  ? "active"
                  : ""
              } ${
                index === activeIndex - 1 ||
                (index === homeBanners?.length - 1 && activeIndex === 0)
                  ? "previous"
                  : ""
              } ${
                index === activeIndex + 1 ||
                (index === 0 && activeIndex === homeBanners?.length - 1)
                  ? "next"
                  : ""
              }`}
            >
              <div className="item">
                <div className="shadow"></div>
                <img src={`${slide?.banner_image}`} alt={`Slide ${index}`} />
              </div>
            </div>
            <div
              className={`slide right ${
                index === activeIndex ||
                index === activeIndex - 1 ||
                (index === homeBanners?.length - 1 && activeIndex === 0)
                  ? "active"
                  : ""
              } ${
                index === activeIndex - 1 ||
                (index === homeBanners?.length - 1 && activeIndex === 0)
                  ? "previous"
                  : ""
              } ${
                index === activeIndex + 1 ||
                (index === 0 && activeIndex === homeBanners?.length - 1)
                  ? "next"
                  : ""
              }`}
            >
              <div className="item">
                <div className="shadow"></div>
                <img src={`${slide?.banner_image}`} alt={`Slide ${index}`} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {homeBanners?.map((slide, index) => (
        <div className="slideshow-text" key={index}>
          <div className="container">
            <div className="item">
              <div
                className={`heading ${index === activeIndex ? "active" : ""} ${
                  index === activeIndex - 1 ||
                  (index === homeBanners?.length - 1 && activeIndex === 0)
                    ? "previous"
                    : ""
                } ${
                  index === activeIndex + 1 ||
                  (index === 0 && activeIndex === homeBanners?.length - 1)
                    ? "next"
                    : ""
                }`}
              >
                {slide?.banner_title}
              </div>
            </div>
            <div
              className={`description ${index === activeIndex ? "active" : ""}`}
            >
              <p>{slide?.banner_description}</p>
            </div>
          </div>
        </div>
      ))}
      <div className="dots">
        {homeBanners?.map((slide, index) => (
          <div
            className={`dot-line ${index === activeIndex ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
            key={index}
          ></div>
        ))}
      </div>
      <div className="up-btn" onClick={prevSlide}>
        <FaArrowAltCircleUp />
      </div>
      <div className="down-btn" onClick={nextSlide}>
        <FaArrowAltCircleDown />
      </div>
    </div>
  );
}
export default SecondSlider;
