import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { SendPostRequest } from "../http";
import { setPatientProfile } from "../store/global/actions";
import Fruits from "./Theme1DashboardComponents/Fruits";
import FruitsDetail from "./Theme1DashboardComponents/FruitsDetail";
import TotkeyDetail from "./Theme1DashboardComponents/TotkeyDetail";
import Dashboard from "./Theme1DashboardComponents/Dashboard";
import Calculator from "./Theme1DashboardComponents/Calculator";
import Appointment from "./Theme1DashboardComponents/Appointment";
import EAppointment from "./Theme1DashboardComponents/EAppointment";
import Prescripition from "./Theme1DashboardComponents/Prescripition";
import Investigation from "./Theme1DashboardComponents/Investigation";
import WeightManagmentList from "./Theme1DashboardComponents/WeightManagmentList";
import BloodGlucoseList from "./Theme1DashboardComponents/BloodGlucoseList";
import BloodPressureList from "./Theme1DashboardComponents/BloodPressureList";
import Totkey from "./Theme1DashboardComponents/Totkey";
import RegMembers from "./Theme1DashboardComponents/RegMembers";
import ComparativeNutrients from "./Theme1DashboardComponents/ComparativeNutrients";
import DropDownTabs from "./Theme1DashboardTabs/DashboardTabs";

export default function LoginDashboard() {
  const dispatch = useDispatch();
  const [fruitId, setFruitId] = useState();
  const [totkeyId, setTotkeyId] = useState();
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const patientData = useSelector((store) => store.patient.patientLogin);
  const patientProfile = useSelector((store) => store.global.patientProfile);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await SendPostRequest("healthcare/get-profile", {
        logged_id: patientData?.logged_id,
      });
      if (response.status === "success") {
        dispatch(setPatientProfile(response?.data));
      } else {
        console.error(
          "Failed to fetch profile data:",
          response.error || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    document.title = selectedTab;
    fetchFruits();
  }, [selectedTab]);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const [fdata, setFdata] = useState([]);

  const fetchFruits = async () => {
    setIsLoading(true);
    try {
      const response = await SendPostRequest("home/fruits", {
        host: "drburhan.clinta.biz",
        category: selectedTab,
        logged_id: patientData?.logged_id,
        // logged_id: "902",
      });
      if (response.status === "success") {
        setFdata(response.data || []);
      } else {
        console.error("Error fetching fruits:", response.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching fruits:", error);
      setIsLoading(false);
    }
  };

  const sitedata = useSelector((store) => store.global.loginData);

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <h2 className="cs_banner_title cs_fs_60">
            WELCOME "
            {patientProfile?.first_name + " " + patientProfile?.last_name}"
            {/* WELCOME "{patientData?.fullname}" */}
          </h2>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-3 col-md-3 col-sm-12">
              <div
                className="profile"
                style={{
                  backgroundImage: "url(/assets/img/profileBg.avif)",
                }}
              >
                <div className="fadee">
                  <div
                    className="profile-img"
                    style={{
                      backgroundImage: `url(${patientProfile?.profile_img})`,
                      // backgroundImage: "url(/assets/img/profileImg.jpg)",
                    }}
                  ></div>
                  <h4>
                    {patientProfile?.first_name +
                      " " +
                      patientProfile?.last_name}
                  </h4>
                  {/* <h4>{patientData?.fullname}</h4> */}
                  <p>MRN : {patientProfile?.mrn_code}</p>
                  <div className="stats">
                    <div className="stat">
                      <p>{patientProfile?.bmi}</p>
                      <p>BMI</p>
                    </div>
                    <div className="stat">
                      <p>{patientProfile?.bfr} %</p>
                      <p>BFR</p>
                    </div>
                    <div className="stat">
                      <p>{patientProfile?.ibw} kg</p>
                      <p>IBW</p>
                    </div>
                  </div>
                </div>
              </div>
              <DropDownTabs
                setFruitId={setFruitId}
                setTotkeyId={setTotkeyId}
                onTabSelect={handleTabSelect}
              />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              {(selectedTab === "" ||
                selectedTab === "fruits" ||
                selectedTab === "vegetables" ||
                selectedTab === "pulses-grains" ||
                selectedTab === "miscellaneous" ||
                selectedTab === "dry-fruites") && (
                <Fruits
                  setSelectedTab={setSelectedTab}
                  selectedTab={selectedTab}
                  fruitId={fruitId}
                  setFruitId={setFruitId}
                  fdata={fdata}
                  fetch={fetchFruits}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              )}
              {selectedTab === "Dashboard" &&
                (fruitId ? (
                  <FruitsDetail fruitId={fruitId} />
                ) : totkeyId ? (
                  <TotkeyDetail totkeyId={totkeyId} />
                ) : (
                  <Dashboard
                    fetchProfileData={fetchProfileData}
                    setTotkeyId={setTotkeyId}
                    setFruitId={setFruitId}
                  />
                ))}

              {selectedTab === "Calculator" && <Calculator />}

              {selectedTab === "Appointment" && <Appointment />}
              {selectedTab === "E-Appointment" && <EAppointment />}
              {selectedTab === "Prescripition" && <Prescripition />}
              {selectedTab === "Investigation" && <Investigation />}
              {selectedTab === "Weight_managment" && <WeightManagmentList />}
              {selectedTab === "Blood_glucose" && <BloodGlucoseList />}
              {selectedTab === "Blood_pressure" && <BloodPressureList />}
              {selectedTab === "RegMembers" && <RegMembers />}
              {selectedTab === "Totkey" && <Totkey />}

              {selectedTab === "Comparative Nutrients" && (
                <ComparativeNutrients />
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer showSection={false} />
    </>
  );
}
