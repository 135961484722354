import React, { useEffect, useState } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { SendPostRequest } from "../http";
import { setPatientProfile } from "../store/global/actions";
import DropDownTabs from "./Theme2DashboardTabs/DashboardTabs";
import DryFruits from "./Theme2DashboardComponents/DryFruits";
import FruitsDetail from "./Theme2DashboardComponents/FruitsDetail";
import Dashboard from "./Theme2DashboardComponents/Dashboard";
import TotkeyDetail from "./Theme2DashboardComponents/TotkeyDetail";
import Calculator from "./Theme2DashboardComponents/Calculator";
import Appointment from "./Theme2DashboardComponents/Appointment";
import EAppointment from "./Theme2DashboardComponents/EAppointment";
import Prescripition from "./Theme2DashboardComponents/Prescripition";
import Investigation from "./Theme2DashboardComponents/Investigation";
import WeightManagmentList from "./Theme2DashboardComponents/WeightManagmentList";
import BloodGlucoseList from "./Theme2DashboardComponents/BloodGlucoseList";
import Totkey from "./Theme2DashboardComponents/Totkey";
import RegMembers from "./Theme2DashboardComponents/RegMembers";
import BloodPressureList from "./Theme2DashboardComponents/BloodPressureList";
import ComparativeNutrients from "./Theme2DashboardComponents/ComparativeNutrients";
import Fruits from "./Theme2DashboardComponents/Fruits";

export default function LoginDashboard() {
  const dispatch = useDispatch();
  const [fruitId, setFruitId] = useState();
  const [totkeyId, setTotkeyId] = useState();
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const patientData = useSelector((store) => store.patient.patientLogin);
  const patientProfile = useSelector((store) => store.global.patientProfile);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchProfileData();
    window.scrollTo(0, 0);
  }, []);

  const fetchProfileData = async () => {
    try {
      const response = await SendPostRequest("healthcare/get-profile", {
        logged_id: patientData?.logged_id,
      });
      if (response.status === "success") {
        dispatch(setPatientProfile(response?.data));
      } else {
        console.error(
          "Failed to fetch profile data:",
          response.error || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    document.title = selectedTab;
    fetchFruits();
  }, [selectedTab]);

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const [fdata, setFdata] = useState([]);

  const fetchFruits = async () => {
    setIsLoading(true);
    try {
      const response = await SendPostRequest("home/fruits", {
        host: "drburhan.clinta.biz",
        category: selectedTab,
        logged_id: patientData?.logged_id,
        // logged_id: "902",
      });
      if (response.status === "success") {
        setFdata(response.data || []);
      } else {
        console.error("Error fetching fruits:", response.message);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching fruits:", error);
      setIsLoading(false);
    }
  };

  const sitedata = useSelector((store) => store.global.loginData);

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <div class="page-title-wrap typo-white mb-5">
        <div
          class="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span class="black-overlay"></span>
          <div class="container">
            <div class="row text-left">
              <div class="col-md-12">
                <div class="page-title-inner">
                  <h1 class="page-title mb-0">
                    Welcome{" "}
                    {patientProfile?.first_name +
                      " " +
                      patientProfile?.last_name}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div
          className="new_profile"
          style={{
            backgroundImage: "url(/assets/img/profileBg.avif)",
          }}
        >
          <div className="row ">
            <div className="col-lg-3">
              <div className="new_profile-img_wapper">
                <span
                  className="profile-img"
                  style={{
                    backgroundImage: `url(${patientProfile?.profile_img})`,
                  }}
                ></span>
              </div>
            </div>
            <div className="col-lg-9">
              <h4>
                {patientProfile?.first_name + " " + patientProfile?.last_name}
              </h4>
              <p>MRN : {patientProfile?.mrn_code}</p>
              <div className="theme_stats">
                <div className="theme_sub_stat">
                  <p>{patientProfile?.bmi}</p>
                  <p>BMI</p>
                </div>
                <div className="theme_sub_stat">
                  <p>{patientProfile?.bfr} %</p>
                  <p>BFR</p>
                </div>
                <div className="theme_sub_stat">
                  <p>{patientProfile?.ibw} kg</p>
                  <p>IBW</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        
        <div className="row mt-4 mb-4">
          <div className="col-lg-3 col-md-3 col-sm-12">
            {/* <div
              className="profile"
              style={{
                backgroundImage: "url(/assets/img/profileBg.avif)",
              }}
            >
              <div className="fadee">
                <div
                  className="profile-img"
                  style={{
                    backgroundImage: `url(${patientProfile?.profile_img})`,
                  }}
                ></div>
                <h4>
                  {patientProfile?.first_name + " " + patientProfile?.last_name}
                </h4>
                <p>MRN : {patientProfile?.mrn_code}</p>
                <div className="stats">
                  <div className="stat">
                    <p>{patientProfile?.bmi}</p>
                    <p>BMI</p>
                  </div>
                  <div className="stat">
                    <p>{patientProfile?.bfr} %</p>
                    <p>BFR</p>
                  </div>
                  <div className="stat">
                    <p>{patientProfile?.ibw} kg</p>
                    <p>IBW</p>
                  </div>
                </div>
              </div>
            </div> */}
            <DropDownTabs
              setFruitId={setFruitId}
              setTotkeyId={setTotkeyId}
              onTabSelect={handleTabSelect}
            />
          </div>
          <div className="col-lg-9 col-md-9 col-sm-12">
            {(selectedTab === "" ||
              selectedTab === "fruits" ||
              selectedTab === "vegetables" ||
              selectedTab === "pulses-grains" ||
              selectedTab === "miscellaneous" ||
              selectedTab === "dry-fruites") && (
              <Fruits
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                fruitId={fruitId}
                setFruitId={setFruitId}
                fdata={fdata}
                fetch={fetchFruits}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
            {selectedTab === "Dashboard" &&
              (fruitId ? (
                <FruitsDetail fruitId={fruitId} />
              ) : totkeyId ? (
                <TotkeyDetail totkeyId={totkeyId} />
              ) : (
                <Dashboard
                  fetchProfileData={fetchProfileData}
                  setTotkeyId={setTotkeyId}
                  setFruitId={setFruitId}
                />
              ))}

            {selectedTab === "Calculator" && <Calculator />}

            {selectedTab === "Appointment" && <Appointment />}
            {selectedTab === "E-Appointment" && <EAppointment />}
            {selectedTab === "Prescripition" && <Prescripition />}
            {selectedTab === "Investigation" && <Investigation />}
            {selectedTab === "Weight_managment" && <WeightManagmentList />}
            {selectedTab === "Blood_glucose" && <BloodGlucoseList />}
            {selectedTab === "Blood_pressure" && <BloodPressureList />}
            {selectedTab === "RegMembers" && <RegMembers />}
            {selectedTab === "Totkey" && <Totkey />}

            {selectedTab === "Comparative Nutrients" && (
              <ComparativeNutrients />
            )}
          </div>
        </div>
      </div>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer showSection={false} />
    </>
  );
}
