import React from 'react'
import { useSelector } from "react-redux";
import About from './HospitalFolder/About';
import AboutMe from './DoctorFolder/About';

function AboutContainer() {
    const sitedata = useSelector((store) => store.global.loginData);

    return (
        <div>
            {sitedata?.site_type === 'hospitals' ? <About /> : <AboutMe />}
        </div>
    )
}

export default AboutContainer;
