import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";

const WeightManageChart = ({ data, loading }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      xAxis: {
        type: "category",
        data: data?.label,
        // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Series 1",
          data: data?.value,
          // data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: "line",
          smooth: true,
          lineStyle: {
            color: "#4F81BC",
          },
          itemStyle: {
            color: "#4F81BC",
          },
        },
      ],
    };

    const chart = echarts.init(chartRef.current);
    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [data]);

  return loading ? (
    <div className="text-center">
      <Spinner />
    </div>
  ) : (
    <>
      <div className="text-center fw-bold">
        {data?.length < 1 ? "No data found" : ""}
      </div>
      <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
    </>
  );
};

export default WeightManageChart;
