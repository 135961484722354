import React from "react";
import { useSelector } from "react-redux";
import AboutUs from "./Hospital/AboutUs";
import AboutMe from "./Doctor/AboutMe";

function AboutContainer() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <div>{sitedata?.site_type === "hospitals" ? <AboutUs /> : <AboutMe />}</div>
  );
}

export default AboutContainer;
