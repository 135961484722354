import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";
import { SendPostRequest } from "../../../http";

function UpdateProfile({ fetchProfileData, onTabSelect }) {
  const cnicInputRef = useRef(null);
  const contactNumberInputRef = useRef(null);
  const patientProfile = useSelector((store) => store.global.patientProfile);
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    contact_number: null,
  });
  const [profileImage, setProfileImage] = useState(null);

  const [profileData, setProfileData] = useState({
    title: "",
    first_name: "",
    last_name: "",
    father_name: "",
    contact_number: "",
    gender: "",
    date_of_birth: "",
    cnic: "",
    profession: "",
    martial_status: "",
    blood_group: "",
    city: "",
    profile_img: "",
    address: "",
    height: "",
    waist: "",
    neck: "",
  });

  const handleFileChange = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setProfileImage(base64String);
    };
    reader.readAsDataURL(file);
  };

  const titleOptions = [
    { value: "Dr.", label: "Dr." },
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
    { value: "Ms.", label: "Ms." },
    { value: "Prof.", label: "Prof." },
  ];

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const martialOptions = [
    { value: "Married", label: "Married" },
    { value: "Single", label: "Single" },
    { value: "Divorced", label: "Divorced" },
  ];

  const bloodgroupOptions = [
    { value: "Unknown", label: "Unknown" },
    { value: "A+", label: "A+" },
    { value: "A-", label: "A-" },
    { value: "B+", label: "B+" },
    { value: "B+-", label: "B-" },
    { value: "AB+", label: "AB+" },
    { value: "AB-", label: "AB-" },
    { value: "O+", label: "O+" },
    { value: "O-", label: "O-" },
  ];

  useEffect(() => {
    setProfileData(patientProfile);
  }, [patientProfile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    let updatedValue = value;
    let error = null;

    if (name === "contact_number") {
      updatedValue = value.slice(0, 11);
      error = updatedValue.length < 11 ? "Invalid Contact Number" : null;
    }

    if (name === "cnic") {
      updatedValue = value.replace(/\D/g, "");
      if (updatedValue.length > 5)
        updatedValue = updatedValue.replace(/(.{5})/, "$1-");

      if (updatedValue.length > 13)
        updatedValue = updatedValue.replace(/(.{13})/, "$1-");

      updatedValue = updatedValue.slice(0, 15);
      if (value !== "" && updatedValue.length !== 15) {
        error = "Invalid CNIC Number";
      } else {
        error = null;
      }
    }

    setError((prevError) => ({ ...prevError, [name]: error }));
    setProfileData((prevData) => ({
      ...prevData,
      [name]:
        name === "date_of_birth" ? formatDate(updatedValue) : updatedValue,
    }));
  };

  const formatDate = (date) => {
    const [year, month, day] = date.split("-");
    return `${month}-${day}-${year}`;
  };

  const deFormatDate = (date) => {
    const [month, day, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  const handleSelectChange = (name, selectedOption) => {
    setProfileData({ ...profileData, [name]: selectedOption?.value });
  };

  const validateContactNumber = () => {
    return profileData.contact_number.length === 11;
  };

  const validateCNIC = () => {
    return profileData?.cnic.length === 15 || profileData?.cnic.length === 0;
  };

  const validate = () => {
    const contactNumberValid = validateContactNumber();
    const cnicValid = validateCNIC();

    contactNumberValid && cnicInputRef.current.focus();
    cnicValid && contactNumberInputRef.current.focus();

    setError((prevError) => ({
      ...prevError,
      contact_number: contactNumberValid ? null : "Invalid Contact Number",
      cnic: cnicValid ? null : "Invalid CNIC Number",
    }));

    return contactNumberValid && cnicValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = validate();
    if (!isValid) return;

    setLoading(true);
    try {
      const formData = { ...profileData };
      formData.profile_img = profileImage || "";
      const response = await SendPostRequest("healthcare/update-profile", {
        host: "drburhan.clinta.biz",
        logged_id: patientData?.logged_id,
        form_data: formData,
      });
      setLoading(false);
      fetchProfileData();
      if (response.status === "success") {
        toast.success(response.message);
        console.log("Profile updated successfully");
        onTabSelect("WeightManagment");
      } else {
        toast.error(response.message);
        console.error(
          "Failed to update profile:",
          response.error || "Unknown error"
        );
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  return (
    <div>
      {loading && (
        <div className="d-flex justify-content-center">
          <Spinner />
        </div>
      )}
      {!loading && (
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h4 className="cs_fs_20 cs_medium mb-0">Update Profile</h4>
              <div className="cs_height_42 cs_height_xl_25"></div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-4">
                    <label className="cs_input_label cs_heading_color">
                      Title
                    </label>
                    <Select
                      required
                      name="title"
                      className="updateprofile_select"
                      placeholder="Select"
                      options={titleOptions}
                      value={{
                        label: profileData?.title,
                        value: profileData?.title,
                      }}
                      onChange={(selectedOption) =>
                        handleSelectChange("title", selectedOption)
                      }
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-4">
                    <label className="cs_input_label cs_heading_color">
                      First Name
                    </label>
                    <input
                      required
                      type="text"
                      className="updateprofile_input"
                      placeholder="Enter First Name"
                      name="first_name"
                      value={profileData?.first_name}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-4">
                    <label className="cs_input_label cs_heading_color">
                      Last Name
                    </label>
                    <input
                      required
                      type="text"
                      className="updateprofile_input"
                      placeholder="Enter Last Name"
                      name="last_name"
                      value={profileData?.last_name}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Father Name
                    </label>
                    <input
                      type="text"
                      className="updateprofile_input"
                      placeholder="Enter Father Name"
                      name="father_name"
                      value={profileData?.father_name}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Contact Number
                    </label>
                    <input
                      required
                      type="number"
                      className={`updateprofile_input ${
                        error?.contact_number && "border-danger"
                      }`}
                      placeholder="Enter Number"
                      name="contact_number"
                      value={profileData?.contact_number}
                      onChange={handleInputChange}
                      ref={contactNumberInputRef}
                    />
                    <div className="cs_height_42 cs_height_xl_25 text-danger"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Gender
                    </label>
                    <Select
                      name="gender"
                      className="updateprofile_select"
                      placeholder="Select"
                      options={genderOptions}
                      value={{
                        label: profileData?.gender,
                        value: profileData?.gender,
                      }}
                      onChange={(selectedOption) =>
                        handleSelectChange("gender", selectedOption)
                      }
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      className="updateprofile_input"
                      placeholder="Select Date"
                      name="date_of_birth"
                      value={deFormatDate(profileData?.date_of_birth)}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      CNIC
                    </label>
                    <input
                      type="text"
                      className={`updateprofile_input ${
                        error?.cnic && "border-danger"
                      }`}
                      placeholder="Enter CNIC"
                      name="cnic"
                      value={profileData?.cnic}
                      onChange={handleInputChange}
                      ref={cnicInputRef}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Profession
                    </label>
                    <input
                      type="text"
                      className="updateprofile_input"
                      placeholder="Enetr Profession"
                      name="profession"
                      value={profileData?.profession}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Martial Status
                    </label>
                    <Select
                      name="martial_status"
                      className="updateprofile_select"
                      placeholder="Select"
                      options={martialOptions}
                      value={{
                        label: profileData?.martial_status,
                        value: profileData?.martial_status,
                      }}
                      onChange={(selectedOption) =>
                        handleSelectChange("martial_status", selectedOption)
                      }
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Blood Group
                    </label>
                    <Select
                      name="blood_group"
                      className="updateprofile_select"
                      placeholder="Select"
                      options={bloodgroupOptions}
                      value={{
                        label: profileData?.blood_group,
                        value: profileData?.blood_group,
                      }}
                      onChange={(selectedOption) =>
                        handleSelectChange("blood_group", selectedOption)
                      }
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      City
                    </label>
                    {/* <Select
                      name="city"
                      className="updateprofile_select"
                      placeholder="Select"
                      options={citiesOptions}
                      value={{
                        label: profileData?.city,
                        value: profileData?.city,
                      }}
                      onChange={(selectedOption) =>
                        handleSelectChange("city", selectedOption)
                      }
                    /> */}

                    <input
                      type="text"
                      className="updateprofile_input"
                      placeholder="Enter City"
                      name="city"
                      value={profileData?.city}
                      onChange={handleInputChange}
                    />

                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-6 updateprofile_image_wrapper">
                    <div className="">
                      <label className="cs_input_label cs_heading_color">
                        Image
                      </label>
                      <input
                        type="file"
                        className="updateprofile_image_input"
                        placeholder="example@gmail.com"
                        name="profile_img"
                        onChange={handleFileChange}
                        accept="image/*"
                      />

                      {/* <img src='/assets/img/profileImg.jpg' /> */}
                    </div>
                    <div
                      className="updateprofile_image_randering"
                      style={{
                        backgroundImage: `url(${profileData?.profile_img})`,
                      }}
                    />

                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-12">
                    <label className="cs_input_label cs_heading_color">
                      Address
                    </label>
                    <input
                      type="text"
                      className="updateprofile_input"
                      placeholder="Enter Address"
                      name="address"
                      value={profileData?.address}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-12">
                    <h4 className="cs_fs_20 cs_medium">Auto Calculation</h4>
                    <p>
                      These parameters are required for auto-calculation of your
                      body fat percentage, BMI and many other health indicators
                      to be shown at your dashboard.
                    </p>
                  </div>
                  <div className="col-lg-4">
                    <label className="cs_input_label cs_heading_color">
                      Height in Inches
                    </label>
                    <input
                      type="number"
                      className="updateprofile_input"
                      placeholder="Enter Height"
                      name="height"
                      value={profileData?.height}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-4">
                    <label className="cs_input_label cs_heading_color">
                      Waist
                    </label>
                    <input
                      type="number"
                      className="updateprofile_input"
                      placeholder="Enter Waist"
                      name="waist"
                      value={profileData?.waist}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-4">
                    <label className="cs_input_label cs_heading_color">
                      Neck
                    </label>
                    <input
                      type="number"
                      className="updateprofile_input"
                      placeholder="Enter Neck"
                      name="neck"
                      value={profileData?.neck}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-12 update_profile_btn_wrapper">
                    <div className="cs_height_5 text-danger"></div>
                    <button type="submit" className="update_profile_btn">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UpdateProfile;
