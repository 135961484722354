import React from "react";

function HomeIntro() {
  return (
    <section id="section-about" className="section-about pb-0 mb-5">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-lg-5">
            <div className="about-wrap relative">
              <div className="about-wrap-inner">
                <div className="about-wrap-details overlap-icon">
                  <div className="text-center">
                    <div className="about-img">
                      <img
                        src="/assets/images/about-us/about-1.jpg"
                        className="b-radius-10"
                        alt="about-img"
                      />
                    </div>
                  </div>
                  <div className="video-wrap wrap-stretch relative">
                    <div className="video-wrap-details">
                      <div className="video-play-btn text-center">
                        <div className="video-icon">
                          <a
                            className="popup-youtube box-shadow1"
                            href="https://www.youtube.com/watch?v=1hLBCOlptq8"
                          >
                            <i className="ti-control-play typo-dark"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 ps-lg-4">
            <div className="title-wrap relative margin-bottom-30">
              <div className="section-title margin-bottom-25">
                <h2 className="section-title mb-0 text-uppercase">
                  Till Your Dreams We{" "}
                  <span className="theme-color">Make It Real</span>
                </h2>
                <span className="section-border-bottom"></span>
              </div>
              <div className="pad-top-15">
                <p className="margin-bottom-20">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="feature-box-wrap f-box-style-1 relative">
                  <div className="feature-box-details">
                    <div className="feature-icon margin-bottom-20">
                      <span className="bi bi-recycle b-radius-50 d-block"></span>
                    </div>
                    <div className="feature-content">
                      <div className="feature-title relative margin-bottom-10">
                        <h5>Updates</h5>
                      </div>
                      <p className="mb-0">
                        There are many variations of passages of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-box-wrap f-box-style-1 relative">
                  <div className="feature-box-details">
                    <div className="feature-icon margin-bottom-20">
                      <span className="bi bi-gear b-radius-50 d-block"></span>
                    </div>
                    <div className="feature-content">
                      <div className="feature-title relative margin-bottom-10">
                        <h5>Flexible</h5>
                      </div>
                      <p className="mb-0">
                        There are many variations of passages of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="feature-box-wrap f-box-style-1 relative">
                  <div className="feature-box-details">
                    <div className="feature-icon margin-bottom-20">
                      <span className="bi bi-palette b-radius-50 d-block"></span>
                    </div>
                    <div className="feature-content">
                      <div className="feature-title relative margin-bottom-10">
                        <h5>Custom Colors</h5>
                      </div>
                      <p className="mb-0">
                        There are many variations of passages of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HomeIntro;
