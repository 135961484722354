import React, { useState, useEffect } from "react";
import { FaDownload } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { Spinner as SpinnerBoot } from "react-bootstrap";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";

function Investigation() {
  const [investigationData, setInvestigationData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [uniqueNames, setUniqueNames] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await SendPostRequest("healthcare/lab_reports", {
        // user_id: '03125049462',
        user_id: patientLogin?.contact_num,
      });
      setUniqueNames([
        // ...new Set(res?.data.map((item) => item?.patient_name.slice(12))),
        ...new Set(res?.data.map((item) => item?.patient_name)),
      ]);
      setInvestigationData(res?.data);
      setFilteredData(res?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const handleReportDownload = (reportUrl) => {
    window.open(reportUrl, "_blank");
  };

  useEffect(() => {
    if (selectedPatient === "all") {
      setFilteredData(investigationData);
    } else {
      setFilteredData(
        investigationData.filter(
          (patient) => patient.patient_name === selectedPatient
          // (patient) => patient.patient_name.slice(12) === selectedPatient
        )
      );
    }
  }, [selectedPatient]);

  return (
    <div className="Prescripition">
      <div className="d-flex   justify-content-between">
        <h3>Investigation</h3>
        {isLoading ? (
          <div className="d-flex align-items-center justify-content-center ">
            <SpinnerBoot />
          </div>
        ) : (
          <select
            className="text-capitalize investigations_filters"
            onClick={(e) => setSelectedPatient(e.target.value)}
            name="filterPatient"
            id="filterPatient"
            placeholder="Select a Patient"
          >
            <option className="" value="all">
              all
            </option>
            {uniqueNames.map((item, index) => (
              <option value={item} key={index}>
                {item}
              </option>
            ))}
          </select>
        )}
      </div>

      {isLoading ? (
        <div
          style={{ marginBottom: "60px" }}
          className="d-flex align-items-center justify-content-center "
        >
          <Spinner />
        </div>
      ) : (
        <div className="table-responsive">
          <table class="table mt-2">
            <thead class="thead-light">
              <tr>
                <th scope="col">SR.NO</th>
                <th scope="col">Patient Name</th>
                <th scope="col">Service</th>
                <th scope="col">Report Status</th>
                <th scope="col">REPORT DATE</th>
                <th scope="col">REPORT FILE</th>
              </tr>
            </thead>

            <tbody>
              {filteredData.length === 0 ? (
                <tr>
                  <td colSpan="6">
                    <div className="prescription-rapper">Data Not Found</div>
                  </td>
                </tr>
              ) : (
                filteredData.map((item, index) => (
                  <tr key={item?.uid}>
                    <td>{index + 1}</td>
                    <td>{item?.patient_name}</td>
                    <td>{item?.service_name}</td>
                    {/* <td>{item.service_uid}</td> */}
                    <td>{item?.report_status}</td>
                    <td>{item?.test_date}</td>
                    <td>
                      <button
                        className="download"
                        onClick={() => handleReportDownload(item?.report_url)}
                      >
                        <FaDownload />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Investigation;
