import React, { useEffect, useState } from "react";
import Header from "../Header";
import ThirdFooter from "../Footer/ThirdFooter";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { SendPostRequest } from "../../http";

function FirstContactus() {
  const [contactData, setContactData] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getContactData();
  }, []);

  const getContactData = async () => {
    const response = await SendPostRequest("content/contactus");
    setContactData(response?.data);
  };
  return (
    <>
      <Header />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="contact-section" className="contact-section pad-bottom-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-lg-0 mb-4">
              <div
                className="div-bg-img b-radius-20"
                style={{
                  backgroundImage: "url(/assets/images/bg/bg-2.jpg)",
                  height: "290px",
                }}
              >
                <div className="f-box c-page text-center typo-white">
                  <div className="feature-icon margin-bottom-10">
                    <i className="ti-location-pin"></i>
                  </div>
                  <div className="feature-content">
                    <div className="feature-title">
                      <h5 className="mb-2">Our Location</h5>
                    </div>
                    <p className="mb-0">
                      684 West College St. Sun City, United States America.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mb-lg-0 mb-4">
              <div
                className="div-bg-img b-radius-20"
                style={{
                  backgroundImage: "url(/assets/images/bg/bg-2.jpg)",
                  height: "290px",
                }}
              >
                <div className="f-box c-page text-center typo-white">
                  <div className="feature-icon margin-bottom-10">
                    <i className="ti-headphone-alt"></i>
                  </div>
                  <div className="feature-content">
                    <div className="feature-title">
                      <h5 className="mb-2">Phone Number</h5>
                    </div>
                    {contactData && contactData.length > 0 && (
                      <a href={`tel:${contactData[0].land_line_no}`}>
                        {contactData[0].land_line_no}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="div-bg-img b-radius-20"
                style={{
                  backgroundImage: "url(/assets/images/bg/bg-2.jpg)",
                  height: "290px",
                }}
              >
                <div className="f-box c-page text-center typo-white">
                  <div className="feature-icon margin-bottom-10">
                    <i className="ti-email"></i>
                  </div>
                  <div className="feature-content">
                    <div className="feature-title">
                      <h5 className="mb-2">Email Address</h5>
                    </div>
                    {contactData && contactData.length > 0 && (
                    <a href={`mailto:${contactData[0].email}`}>
                      {contactData[0].email}
                    </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section-contact" className="section-contact">
        <div className="container">
          <div className="row">
            <p className="form-message" style={{ display: "none" }}></p>
            <div className="contact-form" data-animation="fadeInLeft">
              <form
                role="form"
                name="contactform"
                id="contactform"
                method="post"
                action="php/contact-form.php"
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-text form-group">
                      <input
                        type="text"
                        name="contact_name"
                        className="input-name form-control"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="input-email form-group">
                      <input
                        type="email"
                        name="contact_email"
                        className="input-email form-control"
                        placeholder="Email"
                      />
                    </div>
                    <div className="input-email form-group">
                      <input
                        type="text"
                        name="contact_phone"
                        className="input-phone form-control"
                        placeholder="Phone"
                      />
                    </div>
                    <div className="textarea-message form-group">
                      <textarea
                        name="contact_message"
                        className="textarea-message form-control"
                        placeholder="Message"
                        rows="4"
                      ></textarea>
                    </div>
                    <button className="btn btn-default btn-block" type="submit">
                      Send Now <i className="icon-paper-plane ms-1"></i>
                    </button>
                  </div>
                  <div className="col-md-6 pad-top-md-30">
                    <div className="cs_map contact-map">
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            contactData &&
                            contactData.length > 0 &&
                            contactData[0].google_map
                              ? contactData[0].google_map
                              : "",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <ThirdFooter />
    </>
  );
}

export default FirstContactus;
