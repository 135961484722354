import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import { SendPostRequest } from "../../http";

function RegMembers() {
  const [memberData, setMemberData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await SendPostRequest("healthcare/register-members", {
        // contact_no: '03125049462',
        contact_no: patientLogin?.contact_num,
      });
      setMemberData(res?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  return (
    <div className="Prescripition">
      <h3>Registered Members</h3>

      {isLoading ? (
        <div
          style={{ marginBottom: "60px" }}
          className="d-flex align-items-center justify-content-center "
        >
          <Spinner />
        </div>
      ) : (
        <div className="table-responsive">
          <table class="table mt-2">
            <thead class="thead-light">
              <tr>
                <th scope="col">SR.NO</th>
                <th scope="col">Register Date</th>
                <th scope="col">MRN</th>
                <th scope="col">Name</th>
                <th scope="col">Category</th>
              </tr>
            </thead>

            <tbody>
              {memberData.length === 0 ? (
                <tr>
                  <td colSpan="6">
                    <div className="prescription-rapper">Data Not Found</div>
                  </td>
                </tr>
              ) : (
                memberData.map((item, index) => (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td>{item?.register_date || "--"}</td>
                    <td>{item?.mrn || "--"}</td>
                    <td>{item?.name || "--"}</td>
                    <td>{item?.category || "--"}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default RegMembers;
