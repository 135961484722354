import React, { useEffect, useState } from "react";
import { Spinner as SpinnerBoot } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IoMdHeart } from "react-icons/io";
import { SendPostRequest } from "../../../../http";
import Spinner from "../../../Spinner";

function DietItems({ setFruitId }) {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [dietItem, setDietItem] = useState([]);
  const [loadingItem, setLoadingItem] = useState(false);
  const [loadingFavItem, setLoadingFavItem] = useState(null);
  const toggleExpandReview = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const getDietFavItem = async () => {
    setLoadingItem(true);
    const response = await SendPostRequest("home/fav-fruits", {
      logged_id: patientData?.logged_id,
      // logged_id: "902",
    });
    setDietItem(response?.data);
    setLoadingItem(false);
  };

  const removeFav = async (id) => {
    setLoadingFavItem(id);
    const response = await SendPostRequest("home/diet_items_fav", {
      item_id: id,
      logged_id: patientData?.logged_id,
      // logged_id: "902",
      fav_action: false,
    });
    getDietFavItem();
    setLoadingFavItem(null);
    // if (response?.status === "success") {
    //   alert(response?.message);
    // }
  };

  useEffect(() => {
    getDietFavItem();
  }, []);

  return (
    <div className="row">
      {loadingItem ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : dietItem?.length === 0 ? (
        <div className="text-center">No favourite item found!</div>
      ) : (
        dietItem?.map((data, index) => (
          <div className="col-4 ">
            <div className="dietCard">
              <div className="row justify-content-between ">
                <div className="col-6">
                  <h6 className=" text-capitalize ">
                    {data.Name} - {data.UrduName}
                  </h6>
                </div>
                <div className="col-6">
                  {/* <Link> */}
                  {loadingFavItem && loadingFavItem === data?.UID ? (
                    <SpinnerBoot className="float-end" />
                  ) : (
                    <IoMdHeart
                      onClick={() => removeFav(data?.UID)}
                      className="cur-pointer float-end fs-4 heart_icon"
                    />
                  )}
                  {/* <h6
                  onClick={() => removeFav(data?.UID)}
                  className="mb-0 text-danger cur-pointer float-end"
                >
                  remove
                </h6> */}
                  {/* </Link> */}
                </div>
                <div
                  onClick={() => setFruitId(data?.UID)}
                  className=" mb-4 text-justify cur-pointer dietItems_description"
                >
                  <img
                    src={data.Image}
                    alt="Fruit Image"
                    className="w-50 float-left"
                  />

                  {truncateText(data.Description, 140)}
                  {/* {expandedIndex === index ? desc : truncateText(desc, 100)}

              {desc?.length > 100 && ( */}
                  <a
                    className="fw-bold"
                    // onClick={() => toggleExpandReview(index)}
                  >
                    See More
                  </a>
                  {/* )} */}
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default DietItems;
