import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function DoctorSideNav() {
  const [isAboutMenuVisible, setAboutMenuVisible] = useState(false);
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);

  const handleSubMenuToggle = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };
  const handleAboutMenuToggle = () => {
    setAboutMenuVisible(!isAboutMenuVisible);
  };
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  return (
    <div>
      <div className="mobile-view-links">
        <ul className="">
          <li>
            <Link to="/">Home</Link>
          </li>

          <li
            // className="menu-item-has-children"
            onClick={handleAboutMenuToggle}
          >
            <span>About Me +</span>
            {isAboutMenuVisible && (
              <ul className="mobile_view_li_links">
                <li>
                  <Link to="/about">Introduction</Link>
                </li>

                {sitedata?.options?.patient_nav === "show" && (
                  <li>
                    <Link to="/reviews">Reviews</Link>
                  </li>
                )}
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
              </ul>
            )}
          </li>

          <li className="" onClick={handleSubMenuToggle}>
            <span>Patient Care +</span>
            {isSubMenuVisible && (
              <ul className="mobile_view_li_links">
                <li>
                  <Link to="/patient-portal">Portal</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/hospital-clinic/0">Hospital/Clinic</Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <Link to="/e_health">E Health</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          <li>
            {patientLogin ? (
              <Link to="/patient">
                <button className="login"> My Account</button>
              </Link>
            ) : (
              <Link to="/login">
                <button className="login">Login</button>
              </Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DoctorSideNav;
