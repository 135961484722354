import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileTabs from "./ProfileTabs/ProfileTabs";
import WeightManagment from "./ProfileTabs/WeightManagment";
import BloodGlucose from "./ProfileTabs/BloodGlucose";
import BloodPressure from "./ProfileTabs/BloodPressure";
import UpdateProfile from "./ProfileTabs/UpdateProfile";
import UpdateAccount from "./ProfileTabs/UpdateAccount";
import FavoritesTabs from "./ProfileTabs/FavoritesTabs/FavoritesTabs";
import DietItems from "./ProfileTabs/FavoritesTabs/DietItems";
import TipsTricks from "./ProfileTabs/FavoritesTabs/TipsTricks";
import RecentTabs from "./ProfileTabs/RecentTabs/RecentTabs";
import RecentItems from "./ProfileTabs/RecentTabs/RecentItems";
import RecentTotkey from "./ProfileTabs/RecentTabs/RecentTotkey";
import BasicAppointment from "./ProfileTabs/RecentTabs/BasicAppointment";
import EAppointmentt from "./ProfileTabs/RecentTabs/EAppointment";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";

function Dashboard({ setFruitId, setTotkeyId, fetchProfileData }) {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [dashboardStats, setDashboardStats] = useState([]);
  const [basicAppointment, setBasicAppointment] = useState(null);
  const [EAppointment, setEAppointment] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toISOString().split("T")[0];
        const requestStatsData = { patient_id: patientData?.contact_num };

        const requestBasicData = {
          patient_id: patientData?.contact_num,
          type: "basic",
          // current_date: formattedCurrentDate,
          from: formattedCurrentDate,
          to: formattedCurrentDate,
        };
        const requestEhealthData = {
          patient_id: patientData?.contact_num,
          type: "e-health",
          // current_date: formattedCurrentDate,
          from: formattedCurrentDate,
          to: formattedCurrentDate,
        };
        let response = await SendPostRequest("patient/stats", requestStatsData);
        setDashboardStats(response?.data);

        response = await SendPostRequest(
          "patient/all_appointments",
          requestBasicData
        );
        setBasicAppointment(response?.data);
        response = await SendPostRequest(
          "patient/all_appointments",
          requestEhealthData
        );
        setEAppointment(response?.data);
      } catch (error) {
        console.error("Error fetching dashboard stats:", error);
      }
    };

    fetchData();
  }, []);

  const [selectedTab, setSelectedTab] = useState("WeightManagment");
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };
  const [selectedFavoriteTab, setSelectedFavoriteTab] = useState("DietItems");
  const handleFavoriteTabSelect = (tab) => {
    setSelectedFavoriteTab(tab);
  };
  const [selectedRecentTab, setSelectedRecentTab] = useState("Items");
  const handleRecentTabSelect = (tab) => {
    setSelectedRecentTab(tab);
  };

  return (
    <>
      <div className="dashboard">
        {dashboardStats?.length === 0 ? (
          <div
            style={{ marginBottom: "100px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner />
          </div>
        ) : (
          <div className="row ">
            <div className="col-xl-4 col-md-6">
              <div className="appointment-detail">
                <h2>Total Appointments</h2>
                {dashboardStats?.length > 0 ? (
                  <h2>{dashboardStats[0].total_count}</h2>
                ) : (
                  <h2>0</h2>
                )}
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="appointment-detail">
                <h2>Basic Appointments</h2>
                {dashboardStats?.length > 0 ? (
                  <h2>{dashboardStats[0].basic_count}</h2>
                ) : (
                  <h2>0</h2>
                )}
              </div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="appointment-detail">
                <h2>E-Health Appointments</h2>
                {dashboardStats?.length > 0 ? (
                  <h2>{dashboardStats[0].e_health_count}</h2>
                ) : (
                  <h2>0</h2>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="ProfileTabsSection">
          <ProfileTabs
            onTabSelect={handleTabSelect}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          {selectedTab === "WeightManagment" && <WeightManagment />}
          {selectedTab === "BloodGlucose" && <BloodGlucose />}
          {selectedTab === "BloodPressure" && <BloodPressure />}
          {selectedTab === "UpdateProfile" && (
            <UpdateProfile
              onTabSelect={handleTabSelect}
              fetchProfileData={fetchProfileData}
            />
          )}
          {selectedTab === "UpdateAccount" && (
            <UpdateAccount onTabSelect={handleTabSelect} />
          )}
        </div>
        <div className="favoriteTabs">
          <h4>Recent</h4>

          <RecentTabs onTabSelect={handleRecentTabSelect} />
          {selectedRecentTab === "Items" && (
            <RecentItems setFruitId={setFruitId} />
          )}
          {selectedRecentTab === "Totky" && (
            <RecentTotkey setTotkeyId={setTotkeyId} />
          )}
          {selectedRecentTab === "BasicAppointment" && (
            <BasicAppointment basicAppointment={basicAppointment} />
          )}
          {selectedRecentTab === "EAppointment" && (
            <EAppointmentt EAppointmenttData={EAppointment} />
          )}
        </div>
        <div className="favoriteTabs">
          <h4>Favourites</h4>

          <FavoritesTabs onTabSelect={handleFavoriteTabSelect} />
          {selectedFavoriteTab === "DietItems" && (
            <DietItems setFruitId={setFruitId} />
          )}
          {selectedFavoriteTab === "tips" && (
            <TipsTricks setTotkeyId={setTotkeyId} />
          )}
        </div>

        {/* <h3 className="mt-5">TODAY'S APPOINTMENTS</h3>
        <h6 className="mt-5">Basic Appointment</h6>

        {!basicAppointment ? (
          <div
            style={{ marginBottom: "100px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner />
          </div>
        ) : basicAppointment?.length === 0 ? (
          <div>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Doctor Name</th>
                    <th>Requested</th>
                    <th>Approved</th>
                    <th>Action</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="alert text-center fw-bold alert-danger"
              role="alert"
            >
              No Today Basic Appointments Found
            </div>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Doctor Name</th>
                  <th>Requested</th>
                  <th>Approved</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {basicAppointment.map((appointment) => (
                  <tr key={appointment?.uid}>
                    <td>{appointment?.doctor}</td>
                    <td>
                    {appointment?.requested_time +
                      "(" +
                      appointment?.shift +
                      ")"}
                  </td>
                    <td>{appointment?.approved_date}</td>
                    <td>
                      <button data-toggle="modal" data-target="#exampleModal">
                        View
                      </button>
                      <div
                        class="modal fade"
                        id="exampleModal"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title" id="exampleModalLabel">
                                Appointment Detail
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p>Details here</p>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <h6 className="mt-5">E-Health Appointment</h6>

        {!EAppointment ? (
          <div
            style={{ marginBottom: "100px" }}
            className="d-flex align-items-center justify-content-center"
          >
            <Spinner />
          </div>
        ) : EAppointment?.length === 0 ? (
          <div>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Doctor Name</th>
                    <th>Requested</th>
                    <th>Approved</th>
                    <th>View</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="alert text-center fw-bold alert-danger"
              role="alert"
            >
              No Today E Appointments Found
            </div>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Doctor Name</th>
                  <th>Appointment</th>
                  <th>View</th>
                </tr>
              </thead>

              <tbody>
                {EAppointment.map((appointment) => (
                  <tr key={appointment?.uid}>
                    <td>{appointment?.doctor}</td>
                    <td>
                    {appointment?.requested_time +
                      "(" +
                      appointment?.shift +
                      ")"}
                  </td>
                    <td>{appointment?.appointment_date}</td>
                    <td>
                      <button>View</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )} */}

        {/* {EAppointment && EAppointment.length > 0 ? (
          <div className="table-responsive">
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Doctor Name</th>
                  <th>Appointment</th>
                  <th>View</th>
                </tr>
              </thead>

              <tbody>
                {EAppointment.map((appointment) => (
                  <tr key={appointment?.uid}>
                    <td>{appointment?.doctor}</td>
                    <td>{appointment?.appointment_date}</td>
                    <td>
                      <button>View</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <div className="table-responsive">
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Doctor Name</th>
                    <th>Requested</th>
                    <th>Approved</th>
                    <th>View</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div
              className="alert text-center fw-bold alert-danger"
              role="alert"
            >
              No Today E Appointments Found
            </div>
          </div>
        )} */}
      </div>
    </>
  );
}

export default Dashboard;
