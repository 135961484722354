import React from "react";
import Spinner from "../../../Spinner";

function EAppointmentt({ EAppointmenttData }) {
  return (
    <div>
      <h6 className="mt-5">E-Health Appointment</h6>

      {!EAppointmenttData ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : EAppointmenttData?.length === 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Doctor Name</th>
                  <th>Requested</th>
                  <th>Approved</th>
                  <th>View</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="alert text-center fw-bold alert-danger" role="alert">
            No Today E Appointments Found
          </div>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Doctor Name</th>
                <th>Appointment</th>
                <th>View</th>
              </tr>
            </thead>

            <tbody>
              {EAppointmenttData.map((appointment) => (
                <tr key={appointment?.uid}>
                  <td>{appointment?.doctor}</td>
                  <td>
                    {appointment?.requested_time +
                      "(" +
                      appointment?.shift +
                      ")"}
                  </td>
                  <td>{appointment?.appointment_date}</td>
                  <td>
                    <button>View</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default EAppointmentt;
