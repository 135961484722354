import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { SendPostRequest } from "../http";
import Spinner from "./Spinner";
import { useSelector } from "react-redux";
import { formatDate } from "../Helper/Helper";

export default function AuthorDetail() {
  const { slug } = useParams();
  const [servicesDetail, setServicesDetail] = useState({});
  const [servicesList, setServicesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    document.title = "Author Detail";
    window.scrollTo(0, 0);
    getServicesDetail();
  }, [slug]);

  const getServicesDetail = async () => {
    try {
      setLoading(true);
      const response = await SendPostRequest("author/detail", { slug });
      // if (response.status === "success" && response?.data) {
      //   if(!response?.data?.author) navigate("/")
      //   setServicesList(response.data.blogs);
      //   setServicesDetail(response.data.author);
      // } else {
      //   console.warn("No service detail found in the response.");
      // }

      if (response.status === "success" && response?.data) {
        if (!response?.data?.author) {
          navigate("/");
        } else {
          setServicesList(response?.data?.blogs);
          setServicesDetail(response?.data?.author);
        }
      } else {
        console.warn("No service detail found in the response.");
      }
    } catch (error) {
      console.error("Error fetching services detail:", error);
    } finally {
      setLoading(false);
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata.theme_color?.split(".css").join("");
    } else {
      themeColor = "default"; // Add a default theme color if needed
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="">
            <h2 className="cs_banner_title cs_fs_65">
              Author: {servicesDetail?.title}
            </h2>
          </div>
        </div>
      </section>
      <div className="container">
        <ol className="breadcrumb"></ol>
        <div className="cs_height_35"></div>
        <div className="cs_height_20"></div>
        <div className="row">
          <div className="col-lg-8 col-md-8 col-12 mobile_view_blogs">
            {loading ? (
              <div className="d-flex align-items-center justify-content-center">
                <Spinner />
              </div>
            ) : (
              <>
                <img
                  src={servicesDetail?.image_url}
                  alt="Blog Details"
                  className="cs_radius_20 services-detail-img mb-4"
                />

                {/* <div class="author_heading-wrapper mb-4">
                  <h3>{servicesDetail?.title}</h3>
                  <div class="cs_posted_by">
                    {formatDate(servicesDetail?.date)}
                  </div>
                </div> */}
                <div className="cs_blog_details">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: servicesDetail?.content,
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {/* <div className="col-lg-4 col-md-4 d-none d-lg-block"> */}
          <div className="col-lg-4 col-md-4 col-12 mobile_view_blogs">
            <div className="cs_sidebar author_detaill">
              <div className="cs_sidebar_item blogs-detail-list">
                <h2 className="cs_sidebar_widget_title">Author Blogs</h2>
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner />
                  </div>
                ) : servicesList?.length > 0 ? (
                  <ul>
                    {servicesList.map((list, index) => (
                      <li key={index}>
                        <div className="blogs_right_listing">
                          <img src={list?.image} alt="Service" />
                          <Link to={`/blog/detail/${list?.slug}`}>
                            {list?.title}
                          </Link>
                        </div>

                        {/* <div class="d-flex justify-content-end w-100 mb-4">
                          <div class="cs_posted_by">
                            {formatDate(servicesDetail?.date)}
                          </div>
                        </div> */}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No other blogs found.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}
