import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";

const BloodPressureChart = ({ data, loading }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const option = {
      xAxis: {
        type: "category",
        data: data?.systolic?.label,
        // data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      },

      legend: {
        data: ["Systolic (large number)", "Diastolic (smaller number)"],
        bottom: 10,
      },

      yAxis: {
        type: "value",
      },
      series: [
        {
          name: "Systolic (large number)",
          data: data?.systolic?.value,
          // data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: "line",
          smooth: true,
          lineStyle: {
            color: "#4F81BC",
          },
          itemStyle: {
            color: "#4F81BC",
          },
        },

        {
          name: "Diastolic (smaller number)",
          data: data?.diastolic?.value,
          // data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: "line",
          smooth: true,
          lineStyle: {
            color: "#C0504E",
          },
          itemStyle: {
            color: "#C0504E", // Change the color of data points for Diastolic
          },
        },
      ],
    };

    const chart = echarts.init(chartRef.current);
    chart.setOption(option);

    return () => {
      chart.dispose();
    };
  }, [data]);

  return loading ? (
    <div className="text-center">
      <Spinner />
    </div>
  ) : (
    <>
      <div className="text-center fw-bold">
        {data?.length < 1 ? "No data found" : ""}
      </div>
      <div ref={chartRef} style={{ width: "100%", height: "400px" }} />
    </>
  );
};

export default BloodPressureChart;
