import React from "react";
import ThirdFooter from "../Footer/ThirdFooter";
import Header from "../Header";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const teamData = [
  {
    id: 1,
    name: "Dr.Oviya Ben",
    designation: "Dentist",
    image: "/assets/images/team/1.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 2,
    name: "Dr.Sno White",
    designation: "Psychologist",
    image: "/assets/images/team/medical/2.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 3,
    name: "Dr.Ken Morgan",
    designation: "Cardiologist",
    image: "/assets/images/team/medical/3.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 4,
    name: "Dr.Aimee Devlin",
    designation: "Surgeon",
    image: "/assets/images/team/medical/4.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 5,
    name: "Dr.Rashed Chotta",
    designation: "Ophthalmologist",
    image: "/assets/images/team/medical/5.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
];

function AboutMe() {
  const owlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  return (
    <>
      <Header />
      <div class="page-title-wrap typo-white mb-5">
        <div
          class="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          p<span class="black-overlay"></span>
          <div class="container">
            <div class="row text-left">
              <div class="col-md-12">
                <div class="page-title-inner">
                  <h1 class="page-title mb-0">About Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="feature-section" className="feature-section">
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-3">
              <div className="title-wrap">
                <div className="section-title margin-bottom-40">
                  <h2 className="section-title mb-0 text-uppercase">
                    About <span className="theme-color">Us</span>
                  </h2>
                  <span className="section-border-bottom"></span>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="section-description">
                <p>
                  Excepteur sint occaecat cupidatat non proident, sunt in culpa
                  officia deserunt mollit anim est laborum. Lorem ipsum dolor
                  sit amet, consectetur adipiscing elit vestibulum nec.
                </p>
              </div>
            </div>
          </div>
          <div className="row" data-animation="fadeInUp">
            <div className="col-lg-4">
              <div className="feature-box-wrap f-box-style-5 relative">
                <div className="feature-box-details">
                  <div className="feature-img">
                    <img
                      className="img-fluid"
                      src="/assets/images/about-us/abt-charity2.jpg"
                      width="768"
                      height="648"
                      alt="feature img"
                    />
                  </div>
                  <div className="feature-content mt-4 mb-3">
                    <div className="feature-title relative margin-bottom-5">
                      <h5>Child Care</h5>
                    </div>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor.
                    </p>
                  </div>
                  <div className="button">
                    <a href="about-us.html" className="btn-link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-box-wrap f-box-style-5 relative">
                <div className="feature-box-details">
                  <div className="feature-img">
                    <img
                      className="img-fluid"
                      src="/assets/images/about-us/abt-charity3.jpg"
                      width="768"
                      height="648"
                      alt="feature img"
                    />
                  </div>
                  <div className="feature-content mt-4 mb-3">
                    <div className="feature-title relative margin-bottom-5">
                      <h5>Tree Planting</h5>
                    </div>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor.
                    </p>
                  </div>
                  <div className="button">
                    <a href="about-us.html" className="btn-link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-box-wrap f-box-style-5 relative">
                <div className="feature-box-details">
                  <div className="feature-img">
                    <img
                      className="img-fluid"
                      src="/assets/images/about-us/abt-charity1.jpg"
                      width="768"
                      height="648"
                      alt="feature img"
                    />
                  </div>
                  <div className="feature-content mt-4 mb-3">
                    <div className="feature-title relative margin-bottom-5">
                      <h5>Fund Rising</h5>
                    </div>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor.
                    </p>
                  </div>
                  <div className="button">
                    <a href="about-us.html" className="btn-link">
                      Read More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="team-section"
        className="team-section pad-top-95 pad-bottom-90 section-bg-img"
        style={{ backgroundImage: `url('/assets/images/bg/bg-1.jpg')` }}
      >
        <div className="container">
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <div className="title-wrap text-center">
                <div className="section-title margin-bottom-60">
                  <h2 className="section-title margin-top-5 mb-0 typo-white text-uppercase">
                    Meet The <span className="theme-color">Team</span>
                  </h2>
                  <span className="section-border-bottom center"></span>
                </div>
              </div>
            </div>
          </div>
          <OwlCarousel
            className="owl-theme team-main-wrapper typo-white"
            {...owlOptions}
          >
            {teamData.map((team) => (
              <div className="item" key={team.id}>
                <div className="team-style-1">
                  <div className="team-inner margin-bottom-20">
                    <div className="team-thumb mb-0 relative">
                      {/* <div className="team-overlay"></div> */}
                      <img
                        src={team.image}
                        className="img-fluid thumb w-100"
                        width="270"
                        height="270"
                        alt={team.name}
                      />
                      {/* <div className="team-overlay-details top-left-overlay typo-dark text-center">
                        <div className="social-icons">
                          <a href={team.social.facebook}>
                            <span className="ti-facebook"></span>
                          </a>
                          <a href={team.social.twitter}>
                            <span className="ti-twitter"></span>
                          </a>
                          <a href={team.social.instagram}>
                            <span className="ti-instagram"></span>
                          </a>
                          <a href={team.social.linkedin}>
                            <span className="ti-linkedin"></span>
                          </a>
                        </div>
                      </div> */}
                    </div>
                    <div className="team-details text-center pad-20">
                      <div className="team-name">
                        <h3 className="mb-0">
                          <a href="#" className="client-name">
                            {team.name}
                          </a>
                        </h3>
                      </div>
                      <div className="team-designation">
                        <p className="mb-0 theme-color">{team.designation}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </section>

      <ThirdFooter />
    </>
  );
}

export default AboutMe;
