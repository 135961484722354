import React from 'react'
import { useSelector } from "react-redux";
import Ehealth from './E_health';
import E_health_doctor from './DoctorFolder/E_health_doctor';

function EHealthContainer() {
    const sitedata = useSelector((store) => store.global.loginData);

    return (
        <div>
            {sitedata?.site_type === 'hospitals' ? <Ehealth/> : <E_health_doctor/>}
        </div>
    )
}

export default EHealthContainer;