import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../../http";

function ComparativeNutrients() {
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const [selectedCategory, setSelectedCategory] = useState("Please-select");
  const [ressponseCategory, setRessponseCategory] = useState("");
  const [majorCategory, setMajorCategory] = useState("");
  const [loadingCategory, setLoadingCategory] = useState(false);

  const [selectedSecondCategory, setSelectedSecondCategory] = useState();
  const [selectedSecondCategory2, setSelectedSecondCategory2] = useState();
  const [selectedSecondCategory3, setSelectedSecondCategory3] = useState();
  const [loadingSecondCategory, setLoadingSecondCategory] = useState(false);

  const [responseSecoundCate1, setRresponseSecoundCate1] = useState();
  const [responseSecoundCate2, setRresponseSecoundCate2] = useState();
  const [responseSecoundCate3, setRresponseSecoundCate3] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // if (selectedCategory === "Please Select") {
    //   setSelectedCategory([]);
    //   return;
    // }

    const fetchData = async () => {
      setLoadingCategory(true);
      try {
        // if (selectedCategory !== "") {
        const response = await SendPostRequest("home/fruits", {
          logged_id: patientLogin?.logged_id,
          category: selectedCategory,
        });

        setRessponseCategory(response.data);
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingCategory(false);
      }
    };

    fetchData();
  }, [selectedCategory]);

  // getting major categories
  useEffect(() => {
    if (selectedCategory === "Please Select") {
      setSelectedCategory([]);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await SendPostRequest("home/diet_categories", {
          host: "drburhan.clinta.biz",
          type: "full",
        });
        setMajorCategory(response.data.category);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, []);

  // getting data of 1st category
  useEffect(() => {
    if (selectedSecondCategory === "Please Select") {
      setSelectedSecondCategory([]);
      return;
    }

    const fetchData = async () => {
      try {
        if (selectedSecondCategory !== "") {
          const response = await SendPostRequest("home/diet_facts", {
            host: "drburhan.clinta.biz",
            diet_id: selectedSecondCategory,
          });
          setRresponseSecoundCate1(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, [selectedSecondCategory]);

  // getting data of 2nd category
  useEffect(() => {
    if (selectedSecondCategory2 === "Please Select") {
      setSelectedSecondCategory2("");
      return;
    }

    const fetchData = async () => {
      try {
        if (selectedSecondCategory2 !== "") {
          const response = await SendPostRequest("home/diet_facts", {
            host: "drburhan.clinta.biz",
            diet_id: selectedSecondCategory2,
          });
          setRresponseSecoundCate2(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, [selectedSecondCategory2]);

  // getting data of 3nd category
  useEffect(() => {
    if (selectedSecondCategory3 === "Please Select") {
      setSelectedSecondCategory3("");
      return;
    }

    const fetchData = async () => {
      try {
        if (selectedSecondCategory3 !== "") {
          const response = await SendPostRequest("home/diet_facts", {
            host: "drburhan.clinta.biz",
            diet_id: selectedSecondCategory3,
          });
          setRresponseSecoundCate3(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, [selectedSecondCategory3]);

  const handleSelectChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleChangeSecondCategory = (e) => {
    const value = e.target.value;
    setSelectedSecondCategory(value);
  };

  const handleChangeSecondCategory2 = (e) => {
    const value = e.target.value;
    setSelectedSecondCategory2(value);
  };
  const handleChangeSecondCategory3 = (e) => {
    const value = e.target.value;
    setSelectedSecondCategory3(value);
  };

  return (
    <div className="ComparativeNutrients">
      <h2>Comparative Nutrients</h2>

      <div className="filter">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <label htmlFor="">Please Select Category:</label>
            <select name="" id="" onChange={handleSelectChange}>
              <option value="Please-select">Please Select</option>
              <option value="">All</option>
              <option value="dry-fruites">Dry Fruits</option>
              <option value="fruits">Fruits</option>
              <option value="miscellaneous">Miscellaneous</option>
              <option value="pulses-grains">Pulses Grains</option>
              <option value="vegetables">Vegetables</option>
            </select>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <label htmlFor="">Compare with:</label>
            <select
              name="second-category"
              id=""
              onChange={handleChangeSecondCategory}
            >
              <option value="">Please Select Category</option>
              {ressponseCategory &&
                ressponseCategory?.length > 0 &&
                ressponseCategory.map((data, index) => (
                  <option key={index} value={data?.UID}>
                    {data?.Name} - {data?.UrduName}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <label htmlFor="">Compare with:</label>
            <select
              name="second-category"
              id=""
              onChange={handleChangeSecondCategory2}
            >
              <option value="">Please Select Category</option>
              {ressponseCategory &&
                ressponseCategory?.length > 0 &&
                ressponseCategory.map((data, index) => (
                  <option key={index} value={data?.UID}>
                    {data?.Name} - {data?.UrduName}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12">
            <label htmlFor="">Compare with:</label>
            <select
              name="second-category"
              id=""
              onChange={handleChangeSecondCategory3}
            >
              <option value="">Please Select Category</option>
              {loadingCategory ? (
                <option value="">Wait...</option>
              ) : (
                ressponseCategory &&
                ressponseCategory?.length > 0 &&
                ressponseCategory.map((data, index) => (
                  <option key={index} value={data?.UID}>
                    {data?.Name} - {data?.UrduName}
                  </option>
                ))
              )}
            </select>
          </div>
        </div>
      </div>

      <div className="items">NUTRITIONAL ITEMS</div>

      <table class="table">
        {majorCategory &&
          Object.entries(majorCategory).map(
            ([categoryKey, categoryValue], key) => (
              <>
                <thead>
                  <tr>
                    <th colspan="4">{categoryKey.toUpperCase()}</th>
                  </tr>
                </thead>
                <tbody>
                  {categoryValue.map((item, key) => (
                    <tr key={key}>
                      <td style={{ width: "25%" }}>
                        {item.title.toUpperCase()}
                        {/* {item.id} */}
                      </td>

                      <td
                        id={"a_" + item?.id}
                        key={key}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        {(() => {
                          let is_val = 0;
                          let result =
                            responseSecoundCate1?.length < 1 ? (
                              <p>--</p>
                            ) : (
                              responseSecoundCate1?.map((subcat, key) =>
                                Object.entries(subcat)
                                  .filter(([key, value]) => {
                                    if (item.id === key) {
                                      is_val = 1;
                                      return true;
                                    }
                                    return false;
                                  })
                                  .map(([key, value]) => (
                                    <p key={key}>{value + " " + item.unit}</p>
                                  ))
                              )
                            );
                          if (is_val === 0) return "--";
                          return result;
                        })()}
                      </td>
                      <td
                        id={"b_" + item?.id}
                        key={key}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        {(() => {
                          let is_val = 0;
                          let result =
                            responseSecoundCate2?.length < 1 ? (
                              <p>--</p>
                            ) : (
                              responseSecoundCate2?.map((subcat, key) =>
                                Object.entries(subcat)
                                  .filter(([key, value]) => {
                                    if (item.id === key) {
                                      is_val = 1;
                                      return true;
                                    }
                                    return false;
                                  })
                                  .map(([key, value]) => (
                                    <p key={key}>{value + " " + item.unit}</p>
                                  ))
                              )
                            );
                          if (is_val === 0) return "--";
                          return result;
                        })()}
                      </td>

                      <td
                        id={"c_" + item?.id}
                        key={key}
                        style={{ width: "25%", textAlign: "center" }}
                      >
                        {(() => {
                          let is_val = 0;
                          let result =
                            responseSecoundCate3?.length < 1 ? (
                              <p>--</p>
                            ) : (
                              responseSecoundCate3?.map((subcat, key) =>
                                Object.entries(subcat)
                                  .filter(([key, value]) => {
                                    if (item.id === key) {
                                      is_val = 1;
                                      return true;
                                    }
                                    return false;
                                  })
                                  .map(([key, value]) => (
                                    <p key={key}>{value + " " + item.unit}</p>
                                  ))
                              )
                            );
                          if (is_val === 0) return "--";
                          return result;
                        })()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            )
          )}
      </table>
    </div>
  );
}

export default ComparativeNutrients;
