import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { SendPostRequest } from "../../http";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import { formatDate } from "../../Helper/Helper";

function HomeNews() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNewsData();
    window.scrollTo(0, 0);
  }, []);

  const getNewsData = async () => {
    const response = await SendPostRequest("news/list");
    setNewsData(response?.data);
    setLoading(false);
  };

  const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };
  return (
    <>
      {loading ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center "
        >
          <Spinner />
        </div>
      ) : newsData.length > 0 ? (
        <div id="services-section" className="services-section semi-dark mt-5">
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8">
                <div className="title-wrap text-center">
                  <div className="section-title margin-bottom-60">
                    <h2 className="section-title mb-0 text-uppercase">
                      Our Latest <span className="theme-color">News</span>
                    </h2>
                    <span className="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <OwlCarousel className="service-main-wrapper" {...options}>
                  {newsData.map((data, index) => (
                    <div className="item" key={index}>
                      <div className="blog-main-wrapper blog-style-1">
                        <div className="blog-inner" style={{ height: "460px" }}>
                          <div className="blog-thumb relative">
                            <img
                              src={data?.image}
                              className="img-fluid"
                              width="768"
                              height="600"
                              alt="blog-img"
                            />
                            <div className="top-meta">
                              <ul className="top-meta-list">
                                <li>
                                  <div className="post-date">
                                    <a>
                                      <i className="ti-calendar"></i>
                                      {formatDate(data?.date)}
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="blog-details pt-0">
                            <div className="blog-title">
                              <h4 className="margin-bottom-10">
                                <Link
                                  to={`/news/detail/${data?.slug}`}
                                  className="blog-name"
                                >
                                  {data?.title}
                                </Link>
                              </h4>
                            </div>
                            <div className="post-desc mt-2">
                              <p>
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </p>
                              <div className="blog-link">
                                <Link
                                  to={`/news/detail/${data?.slug}`}
                                  className="link font-w-500"
                                >
                                  Read More
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default HomeNews;
