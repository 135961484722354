import React, { useState, useEffect } from "react";
import DoctorNav from "./DoctorNav";
import HospitalNav from "./HospitalNav";
import { FiFacebook } from "react-icons/fi";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-bootstrap";
import DoctorSideNav from "./DoctorFolder/DoctorSideNav";
import HospitalSideNav from "./HospitalFolder/HospitalSideNav";
export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const handleSubMenuToggle = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };
  const [isAboutMenuVisible, setAboutMenuVisible] = useState(false);

  const handleAboutMenuToggle = () => {
    setAboutMenuVisible(!isAboutMenuVisible);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  let themeColor;
  if (sitedata) {
    themeColor = sitedata?.theme_color?.split(".css").join("");
  } else {
  }
  return (
    <>
      <div
        className="header-box"
        //  style={{
        //   height: '100px',
        // }}
      >
        <header
          className={`srollbar-header cs_site_header cs_style1 white-bg `}
        >
          <div className="cs_main_header ">
            <div className="container">
              <div className="cs_main_header_in">
                <div className="cs_main_header_left mobile-site-title">
                  <Link
                    to="/"
                    className="cs_site_branding small-screen-logo logo-title-wrapper"
                  >
                    <img
                      src={
                        sitedata?.site_logo
                          ? `${sitedata.site_logo}`
                          : "/assets/img/hospital-static.jpg"
                      }
                      alt="Logo"
                      style={{
                        width: "60px",
                        height: "60px",
                        marginTop: "10px",
                        borderRadius: "10px",
                      }}
                    />
                    <h5 className="mb-0">
                      {sitedata.site_type !== "hospitals" &&
                      sitedata?.site_title?.length > 0 ? (
                        <span>{"Dr. " + sitedata?.site_title}</span>
                      ) : (
                        sitedata?.site_title
                      )}
                    </h5>
                  </Link>
                  {sitedata?.site_type === "hospitals" ? (
                    <HospitalNav />
                  ) : (
                    <DoctorNav />
                  )}
                </div>
                <div className="cs_main_header_right navbar-menu-button">
                  <div className="cs_toolbox">
                    <button
                      className="cs_toolbox_btn cs_search_toggle_btn"
                      type="button"
                    >
                      <svg
                        width="0"
                        height="0"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1684 0C5.91146 0 0 5.90944 0 13.164C0 20.4184 5.91146 26.3394 13.1684 26.3394C16.2681 26.3394 19.1188 25.2535 21.3719 23.4505L26.8571 28.931C27.1339 29.1962 27.5036 29.3426 27.887 29.3386C28.2704 29.3347 28.6371 29.1809 28.9084 28.91C29.1797 28.6392 29.3342 28.2729 29.3386 27.8896C29.3431 27.5064 29.1972 27.1365 28.9322 26.8595L23.4471 21.3762C25.2521 19.1204 26.3397 16.2662 26.3397 13.164C26.3397 5.90944 20.4254 0 13.1684 0ZM13.1684 2.926C18.8435 2.926 23.4099 7.49078 23.4099 13.164C23.4099 18.8371 18.8435 23.4134 13.1684 23.4134C7.4933 23.4134 2.92695 18.8371 2.92695 13.164C2.92695 7.49078 7.4933 2.926 13.1684 2.926Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                    <button
                      class="btn cs_toolbox_btn cs_search_toggle_btn"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasBottom"
                      aria-controls="offcanvasBottom"
                    >
                      <svg
                        width="35"
                        height="30"
                        viewBox="0 0 35 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.483887 2.46544C0.483887 1.10383 1.14618 0 1.96315 0H33.5208C34.3377 0 35 1.10383 35 2.46544C35 3.82708 34.3377 4.93088 33.5208 4.93088H1.96315C1.14618 4.93088 0.483887 3.82708 0.483887 2.46544Z"
                          fill="currentColor"
                        />
                        <path
                          d="M0.483887 14.6694C0.483887 13.3074 1.14618 12.2039 1.96315 12.2039H33.5208C34.3377 12.2039 35 13.3074 35 14.6694C35 16.0309 34.3377 17.1348 33.5208 17.1348H1.96315C1.14618 17.1348 0.483887 16.0309 0.483887 14.6694Z"
                          fill="currentColor"
                        />
                        <path
                          d="M0.483887 26.6267C0.483887 25.2648 1.14618 24.1613 1.96315 24.1613H33.5208C34.3377 24.1613 35 25.2648 35 26.6267C35 27.9883 34.3377 29.0922 33.5208 29.0922H1.96315C1.14618 29.0922 0.483887 27.9883 0.483887 26.6267Z"
                          fill="currentColor"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div>
        <div
          className="offcanvas offcanvas-end"
          tabindex="-1"
          id="offcanvasBottom"
          aria-labelledby="offcanvasBottomLabel"
          style={{ width: "600px " }}
        >
          <div className="cs_sidenav_in">
            <button
              className="cs_close "
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              type="button"
            >
              <img src="/assets/img/icons/close.svg" alt="Close" />
            </button>
            <div className="large-view-links">
              <div className="cs_logo_box">
                <div className="cs_height_15"></div>
                <h3 className="cs_fs_24 cs_semibold mb-0 mt-4">
                  <img
                    src={
                      sitedata?.site_logo
                        ? `${sitedata.site_logo}`
                        : "/assets/img/hospital-static.jpg"
                    }
                    alt="Logo"
                    style={{
                      width: "70px",
                      height: "80px",
                      marginTop: "10px",
                      borderRadius: "10px",
                    }}
                  />
                  <span className="ml-3">
                    {sitedata.site_type !== "hospitals" && (
                      <span>{"Dr. "}</span>
                    )}
                    {sitedata?.site_title}
                  </span>
                </h3>
              </div>
              <div className="cs_height_5"></div>
              <div className="cs_height_70 cs_height_lg_50"></div>
              <div className="cs_iconbox cs_style_11 cs_radius_25">
                <div className="cs_iconbox_icon">
                  <img
                    src={`/assets/img/contact/icon_2_${themeColor}.svg`}
                    alt="Icon"
                  />
                </div>
                <div className="cs_iconbox_right">
                  <h3 className="cs_iconbox_title cs_fs_24 mb-0">Email</h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    {sitedata?.site_email &&
                    sitedata?.site_email?.length > 0 ? (
                      <a href={`mailto:${sitedata?.site_email}`}>
                        {sitedata?.site_email}
                      </a>
                    ) : (
                      "--"
                    )}
                  </p>
                </div>
              </div>
              <div className="cs_height_30"></div>
              <div className="cs_iconbox cs_style_11 cs_radius_25">
                <div className="cs_iconbox_icon">
                  <img
                    src={`/assets/img/contact/icon_1_${themeColor}.svg`}
                    alt="Icon"
                  />
                </div>
                <div className="cs_iconbox_right">
                  <h3 className="cs_iconbox_title cs_fs_24 mb-0">Phone</h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    {sitedata?.site_contact &&
                    sitedata?.site_contact?.length > 0 ? (
                      <a href={`tel:${sitedata?.site_contact}`}>
                        {sitedata?.site_contact}
                      </a>
                    ) : (
                      "--"
                    )}
                  </p>
                </div>
              </div>
              <div className="cs_height_30"></div>
              <div className="cs_iconbox cs_style_11 cs_radius_25">
                <div className="cs_iconbox_icon">
                  {/* <img
                    src={`/assets/img/contact/icon_3_${themeColor}.svg`}
                    alt="Icon"
                  /> */}
                  <FiFacebook className="sidebar-facebook-icon" />
                </div>
                <div className="cs_iconbox_right">
                  <h3 className="cs_iconbox_title cs_fs_24 mb-0">Facebook</h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    {sitedata?.site_extra?.facebook_link?.length > 0 ? (
                      <a
                        target="blank"
                        href={sitedata?.site_extra?.facebook_link}
                      >
                        {/* {sitedata.site_type === "hospitals" ? (
                        <span>Reliance Hospital</span>
                      ) : (
                        <span>Burhan Haque</span>
                      )} */}
                        {sitedata?.site_title}
                      </a>
                    ) : (
                      <>{sitedata?.site_title ? sitedata?.site_title : "--"}</>
                    )}
                  </p>
                </div>
              </div>

              <div className="cs_height_60"></div>
            </div>
            {sitedata?.site_type === "hospitals" ? (
              <HospitalSideNav />
            ) : (
              <DoctorSideNav />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
