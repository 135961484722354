import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";

function EAppointment() {
  const [data, setData] = useState(null);
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [viewData, setViewData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const requestData = {
          patient_id: patientData?.contact_num,
          type: "e-health",
        };
        const response = await SendPostRequest(
          "patient/all_appointments",
          requestData
        );
        setData(response?.data);
      } catch (error) {
        console.error("Error fetching dashboard stats:", error);
      }
    };

    fetchData();
  }, []);

  const handleView = async (uid) => {
    try {
      const requestData = {
        appointment_id: uid,
      };
      const response = await SendPostRequest(
        "patient/appointment_detail",
        requestData
      );
      setViewData(response?.data);
    } catch (error) {
      console.error("Error fetching: ", error);
    }
  };

  return (
    <div className="dashboard">
      <h3>E Appointment</h3>

      {!data ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center"
        >
          <Spinner />
        </div>
      ) : data?.length === 0 ? (
        <div>
          <div className="table-responsive">
            <table className="table">
              <thead className="thead">
                <tr>
                  <th>Doctor Name</th>
                  <th>Requested</th>
                  <th>Approved</th>
                  <th>View</th>
                </tr>
              </thead>
            </table>
          </div>
          <div className="alert text-center fw-bold alert-danger" role="alert">
            No E Appointments Found
          </div>
        </div>
      ) : (
        <div className="table-responsive">
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Doctor Name</th>
                <th>Requested</th>
                <th>Approved</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {data.map((appointment) => (
                <tr key={appointment.uid}>
                  {/* <td>{appointment?.doctor}</td> */}
                  <td>{appointment?.doctor?.contact_number || "--"}</td>
                  <td>
                    {appointment?.requested_time +
                      "(" +
                      appointment?.shift +
                      ")"}
                  </td>
                  <td>{appointment?.approved_date}</td>
                  <td>
                    <button
                      onClick={() => handleView(appointment?.uid)}
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      View
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog" role="document">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Appointment Detail
                            </h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            {viewData && (
                              <div className="row">
                                <div className="col-6">
                                  <h5>Name: </h5>
                                  <p>{viewData[0]?.full_name || "--"}</p>
                                </div>
                                <div className="col-6">
                                  <h5>Doctor: </h5>
                                  <p>{viewData[0]?.doctor || "--"}</p>
                                </div>
                                <div className="col-6">
                                  <h5>Contact Num: </h5>
                                  <p>{viewData[0]?.contact_number || "--"}</p>
                                </div>
                                <div className="col-6">
                                  <h5>Status: </h5>
                                  <p>{viewData[0]?.status || "--"}</p>
                                </div>
                                <div className="col-6">
                                  <h5>Requested Time: </h5>
                                  <p>{viewData[0]?.requested_time || "--"}</p>
                                </div>
                                <div className="col-6">
                                  <h5>Approved Time: </h5>
                                  <p>{viewData[0]?.approved_date}</p>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default EAppointment;
