import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
// import "../../../Theme1Components/Main.css"
function CalculatorTabs({ onTabSelect }) {
  const [selectedTab, setSelectedTab] = useState("Bodyfat");

  const handleNavItemClicked = (heading) => {
    onTabSelect(heading);
    // setOpenDropdown(null);
    setSelectedTab(heading); // Close the dropdown when a tab is clicked
  };

  return (
    <div className="CalculatorTabs">
      <Navbar bg="" expand="lg" variant="dark">
        <Navbar.Toggle aria-controls="main_nav" />
        <Navbar.Collapse id="main_nav">
          <Nav className="mr-auto p-3">
            <Nav.Link
              className={selectedTab === "Bodyfat" ? "active" : ""}
              onClick={() => handleNavItemClicked("Bodyfat")}
            >
              Body Fat Ratio
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "BMI" ? "active" : ""}
              onClick={() => handleNavItemClicked("BMI")}
            >
              BMI
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Body_weight_calculator" ? "active" : ""}
              onClick={() => handleNavItemClicked("Body_weight_calculator")}
            >
             Body Weight Calculator
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Childern_height_predictor" ? "active" : ""}
              onClick={() => handleNavItemClicked("Childern_height_predictor")}
            >
             Childern Height Predictor
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Edd" ? "active" : ""}
              onClick={() => handleNavItemClicked("Edd")}
            >
             Expected date Of Delivery(EDD)
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Height_Converter" ? "active" : ""}
              onClick={() => handleNavItemClicked("Height_Converter")}
            >
             Height Converter
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Weight_Converter" ? "active" : ""}
              onClick={() => handleNavItemClicked("Weight_Converter")}
            >
             Weight Converter
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default CalculatorTabs;
