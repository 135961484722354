import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../../../../http";
import Spinner from "../../../Spinner";

function RecentTotkey({ setTotkeyId }) {
  const patientData = useSelector((store) => store.patient.patientLogin);
  const [recentTotkeyItem, setRecentTotkeyItem] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRecentTotkeyItem = async () => {
    setLoading(true);
    const response = await SendPostRequest("healthcare/recent-totkey-items", {
      logged_id: patientData?.logged_id,
      // logged_id: "663",
    });
    setRecentTotkeyItem(response?.data);
    setLoading(false);
  };

  useEffect(() => {
    getRecentTotkeyItem();
  }, []);
  return (
    <div className="">
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : recentTotkeyItem?.length === 0 ? (
        <div className="text-center">No Recent Totkey item found!</div>
      ) : (
        recentTotkeyItem?.map((data, index) => (
          <div
            onClick={() => setTotkeyId(data?.id)}
            className="cur-pointer recent-totkey"
            key={index}
          >
            <p className="mb-0 text-right">{data?.heading}</p>
          </div>
        ))
      )}
    </div>
  );
}

export default RecentTotkey;
